/**
 * Utility functions for image handling
 */

import TuttoGymLogo from '../images/tuttogym.jpeg';

/**
 * Formats Google Maps photo URLs to make them usable in img tags
 * 
 * @param {string} url - The URL to format
 * @returns {string} - Properly formatted URL
 */
export const formatGooglePhotoUrl = (url) => {
  if (!url) return null;
  
  // Check if it's a Google Places photo reference URL
  if (url && (url.includes('maps.googleapis.com/maps/api/place/photo') || 
      url.includes('photoreference='))) {
    try {
      // If it already has a proper https:// prefix, it's likely correct
      if (url.startsWith('https://')) {
        return url;
      }
      
      // If it starts with @ or has an incorrect prefix, fix it
      if (url.startsWith('@')) {
        url = url.substring(1);
      }
      
      // Ensure URL starts with https://
      if (!url.startsWith('https://')) {
        url = 'https://' + url.replace(/^[/]*/, '');
      }
      
      return url;
    } catch (error) {
      console.error('Error formatting Google photo URL:', error);
      return null;
    }
  }
  
  // If it's not a Google Places photo URL, return as is
  return url;
};

/**
 * Gets a reliable image URL with fallback
 * 
 * @param {string} imageUrl - The primary image URL to use
 * @param {string} fallbackUrl - Optional custom fallback URL
 * @returns {string} - A valid image URL
 */
export const getReliableImageUrl = (imageUrl, fallbackUrl = TuttoGymLogo) => {
  // First try to format the URL if it's a Google photo
  const formattedUrl = formatGooglePhotoUrl(imageUrl);
  
  // If we got a valid URL, return it
  if (formattedUrl) {
    return formattedUrl;
  }
  
  // Otherwise return the fallback
  return fallbackUrl;
};

/**
 * Handles image loading errors by replacing with fallback
 * 
 * @param {Event} event - The error event
 */
export const handleImageError = (event) => {
  // Replace the broken image with fallback
  event.target.src = TuttoGymLogo;
  // Remove the onerror handler to prevent infinite loops
  event.target.onerror = null;
}; 