import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Pagination,
  OutlinedInput,
  Checkbox,
  ListItemText,
  CircularProgress,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import StarIcon from '@mui/icons-material/Star';
import { searchFitnessCenters, getActivityTypes, getCities } from '../services/api';
import { getReliableImageUrl, handleImageError } from '../utils/imageUtils';
// Importar el logo de TuttoGym como imagen de respaldo
import TuttoGymLogo from '../images/tuttogym.jpeg';

// Ejemplo de datos para prevenir errores si la API falla
const defaultGymsData = [];

// Lista predefinida de actividades (valores en español para la API)
const predefinedActivities = [
  "Spinning",
  "Fitness",
  "Zumba",
  "Gimnasio",
  "Aquagym",
  "Padel",
  "Entrenamiento Personal",
  "Polideportivo",
  "Artes Marciales",
  "Pilates",
  "Boxeo",
  "Crossfit",
  "Yoga",
  "Escalada"
];

// Lista de ciudades disponibles
const cities = [
  "Barcelona",
  "Santiago de Chile"
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SearchGyms = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    activities: [],
    city: '',
    postalCode: '',
  });
  
  // Estados para actividades disponibles
  const [availableActivities, setAvailableActivities] = useState([]);
  const [activitiesLoading, setActivitiesLoading] = useState(false);
  
  // Nuevos estados para manejar la API
  const [gyms, setGyms] = useState(defaultGymsData);
  const [pagination, setPagination] = useState({
    total_items: 0,
    total_pages: 0,
    current_page: 1,
    page_size: 9
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  
  // Cargar actividades cuando se monta el componente
  useEffect(() => {
    const loadActivities = async () => {
      setActivitiesLoading(true);
      try {
        const response = await getActivityTypes();
        if (response.success && response.data) {
          setAvailableActivities(response.data);
        }
      } catch (error) {
        console.error("Error loading activities:", error);
      } finally {
        setActivitiesLoading(false);
      }
    };
    
    loadActivities();
  }, []);
  
  // Convertir los tipos de actividades a formato de lista
  const activitiesList = [
    // Opción "Todas"
    { value: 'all', label: t('activities.all') },
    // Mapear las actividades desde la API o usar las predefinidas como fallback
    ...(availableActivities.length > 0 ? 
      availableActivities.map(activity => ({
        value: activity,
        label: t(`activities.${activity.toLowerCase().replace(/ /g, '_')}`, activity)
      })) : 
      predefinedActivities.map(activity => {
        const key = activity.toLowerCase().replace(/ /g, '_');
        const translationKeys = {
          'boxeo': 'boxing',
          'padel': 'padel',
          'yoga': 'yoga',
          'spinning': 'spinning',
          'zumba': 'zumba',
          'gimnasio': 'gimnasio',
          'aquagym': 'aquagym',
          'pilates': 'pilates',
          'crossfit': 'crossfit',
          'fitness': 'fitness',
          'escalada': 'escalada',
          'entrenamiento_personal': 'entrenamiento_personal',
          'polideportivo': 'polideportivo',
          'artes_marciales': 'artes_marciales'
        };
        const translationKey = translationKeys[key] || key;
        return {
          value: activity,
          label: t(`activities.${translationKey}`)
        };
      })
    )
  ];
  
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    
    // Manejo especial para la opción "Todas" en actividades
    if (name === 'activities') {
      if (value.includes('all')) {
        // Si "all" está seleccionado, deseleccionamos todo lo demás
        if (filters.activities.includes('all')) {
          setFilters({
            ...filters,
            activities: []
          });
        } else {
          // Si no está seleccionado "all", entonces solo seleccionamos "all"
          setFilters({
            ...filters,
            activities: ['all']
          });
        }
      } else {
        // Selección normal sin "all"
        setFilters({
          ...filters,
          activities: value
        });
      }
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
    
    setPage(1); // Reset to first page when filters change
  };
  
  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Preparar parámetros de búsqueda
      let searchParams = {};
      
      // Añadir ciudad si está seleccionada
      if (filters.city) {
        searchParams.city = filters.city;
      }
      
      // Añadir código postal si está ingresado
      if (filters.postalCode.trim()) {
        searchParams.postalCode = filters.postalCode.trim();
      }
      
      // Añadir actividades a la búsqueda (excepto 'all')
      if (filters.activities.length > 0 && !filters.activities.includes('all')) {
        // Enviamos los valores originales en español para la API
        searchParams.activityType = filters.activities;
      }
      
      // Validar que haya al menos un filtro
      if (!searchParams.city && !searchParams.postalCode && !searchParams.activityType) {
        setError('Please select at least one filter option');
        setLoading(false);
        return;
      }
      
      // Añadir página actual
      searchParams.page = page;
      
      const response = await searchFitnessCenters(searchParams);
      
      if (response.success !== false && response.data) {
        setGyms(response.data);
        if (response.pagination) {
          setPagination(response.pagination);
        }
        setInitialLoad(false);
      } else {
        setError(response.message || 'Error searching fitness centers');
        setGyms([]);
      }
    } catch (error) {
      setError('Error connecting to server. Please try again later.');
      console.error('Error during search:', error);
      setGyms([]);
    } finally {
      setLoading(false);
    }
  };
  
  // Manejar cambio de página
  const handlePageChange = async (event, value) => {
    // Primero actualizamos la página en el estado
    setPage(value);
    
    // Solo ejecutamos la búsqueda si no es la carga inicial
    if (!initialLoad) {
      // Creamos una copia del objeto de búsqueda con la nueva página
      const searchParams = {};
      
      // Añadir ciudad si está seleccionada
      if (filters.city) {
        searchParams.city = filters.city;
      }
      
      // Añadir código postal si está ingresado
      if (filters.postalCode.trim()) {
        searchParams.postalCode = filters.postalCode.trim();
      }
      
      // Añadir actividades a la búsqueda (excepto 'all')
      if (filters.activities.length > 0 && !filters.activities.includes('all')) {
        searchParams.activityType = filters.activities;
      }
      
      // Establecer la nueva página
      searchParams.page = value;
      
      // Llamamos directamente a la búsqueda sin setTimeout
      setLoading(true);
      setError(null);
      
      try {
        const response = await searchFitnessCenters(searchParams);
        
        if (response.success !== false && response.data) {
          setGyms(response.data);
          if (response.pagination) {
            setPagination(response.pagination);
          }
        } else {
          setError(response.message || 'Error searching fitness centers');
          setGyms([]);
        }
      } catch (error) {
        setError('Error connecting to server. Please try again later.');
        console.error('Error during page change:', error);
        setGyms([]);
      } finally {
        setLoading(false);
      }
    }
  };
  
  // Formatea la dirección para mostrar
  const formatAddress = (address) => {
    if (!address) return '';
    // Limitar a 45 caracteres y agregar ...
    return address.length > 45 ? `${address.substring(0, 45)}...` : address;
  };

  return (
    <>
      <Helmet>
        <title>{t('searchGyms.helmet')}</title>
      </Helmet>
      
      <Box 
        sx={{ 
          py: 6, 
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h3" component="h1" gutterBottom align="center">
            {t('searchGyms.title')}
          </Typography>
          
          <Typography variant="h5" component="p" color="text.secondary" align="center" sx={{ mb: 4 }}>
            {t('searchGyms.subtitle')}
          </Typography>
          
          <Grid container spacing={3} justifyContent="center" sx={{ mb: 3 }}>
            {/* Filtro de Ciudad */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="city-label">{t('searchGyms.cityFilter')}</InputLabel>
                <Select
                  labelId="city-label"
                  id="city"
                  name="city"
                  value={filters.city}
                  onChange={handleFilterChange}
                  label={t('searchGyms.cityFilter')}
                >
                  <MenuItem value="">
                    <em>{t('searchGyms.selectCity')}</em>
                  </MenuItem>
                  {cities.map((city) => (
                    <MenuItem key={city} value={city}>
                      {city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            {/* Filtro de Actividades */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="activities-label">{t('searchGyms.activitiesFilter')}</InputLabel>
                <Select
                  labelId="activities-label"
                  id="activities"
                  name="activities"
                  multiple
                  value={filters.activities}
                  onChange={handleFilterChange}
                  input={<OutlinedInput label={t('searchGyms.activitiesFilter')} />}
                  renderValue={(selected) => {
                    if (selected.includes('all')) return t('activities.all');
                    return selected.join(', ');
                  }}
                  MenuProps={MenuProps}
                >
                  {activitiesLoading ? (
                    <MenuItem disabled>
                      <CircularProgress size={20} sx={{ mr: 1 }} />
                      {t('common.loading')}
                    </MenuItem>
                  ) : (
                    activitiesList.map((activity) => (
                      <MenuItem key={activity.value} value={activity.value}>
                        <Checkbox checked={filters.activities.indexOf(activity.value) > -1} />
                        <ListItemText primary={activity.label} />
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>

            {/* Filtro de Código Postal */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="postalCode"
                name="postalCode"
                label={t('searchGyms.postalCodeFilter')}
                value={filters.postalCode}
                onChange={handleFilterChange}
                variant="outlined"
                placeholder={t('searchGyms.enterPostalCode')}
              />
            </Grid>
            
            {/* Botón de Búsqueda */}
            <Grid item xs={12} sm={6} md={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSearch}
                startIcon={<SearchIcon />}
                disabled={loading}
                sx={{ height: '56px' }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : t('searchGyms.searchButton')}
              </Button>
            </Grid>
          </Grid>
          
          {/* Mensajes de error */}
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}
        </Container>
      </Box>
      
      <Container maxWidth="lg" sx={{ py: 6 }}>
        {/* Mostrar resultados o mensaje si no hay resultados */}
        {gyms.length > 0 ? (
          <>
            <Grid container spacing={4}>
              {gyms.map((gym) => (
                <Grid item key={gym.id} xs={12} sm={6} md={4}>
                  <Card 
                    sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 6
                      }
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={getReliableImageUrl(gym.main_photo)}
                      alt={gym.name}
                      onError={(e) => handleImageError(e, TuttoGymLogo)}
                      sx={{ objectFit: 'cover' }}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {gym.name}
                        {gym.rating !== null && gym.rating !== undefined && (
                          <Chip 
                            icon={<StarIcon sx={{ color: 'white !important' }} />}
                            label={typeof gym.rating === 'number' 
                              ? gym.rating.toFixed(1) 
                              : (Number(gym.rating) ? Number(gym.rating).toFixed(1) : '0.0')}
                            size="small"
                            sx={{ 
                              ml: 1, 
                              bgcolor: 'primary.main',
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          />
                        )}
                      </Typography>
                      
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <LocationOnIcon color="action" sx={{ mt: 0.5, mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          {formatAddress(gym.address)}
                          {gym.city ? `, ${gym.city}` : ''}
                          {gym.postal_code ? ` (${gym.postal_code})` : ''}
                        </Typography>
                      </Box>
                      
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                        <FitnessCenterIcon color="action" sx={{ mt: 0.5, mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          {gym.type || t('common.notSpecified')}
                        </Typography>
                      </Box>
                      
                      <Box sx={{ mt: 1 }}>
                        {gym.services && gym.services.slice(0, 3).map((service, index) => (
                          <Chip 
                            key={index}
                            label={service}
                            size="small"
                            sx={{ mr: 0.5, mb: 0.5 }}
                          />
                        ))}
                        {gym.services && gym.services.length > 3 && (
                          <Chip 
                            label={`+${gym.services.length - 3}`}
                            size="small"
                            variant="outlined"
                            sx={{ mb: 0.5 }}
                          />
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            
            {/* Paginación */}
            {pagination.total_pages > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Pagination 
                  count={pagination.total_pages} 
                  page={pagination.current_page} 
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                />
              </Box>
            )}
          </>
        ) : !loading && !initialLoad ? (
          <Box sx={{ textAlign: 'center', py: 8 }}>
            <Typography variant="h5" color="text.secondary" gutterBottom>
              {t('searchGyms.noResults')}
            </Typography>
            <Typography variant="body1">
              {t('searchGyms.tryDifferentSearch')}
            </Typography>
          </Box>
        ) : null}
        
        {/* Loader mientras se cargan resultados */}
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
            <CircularProgress size={60} />
          </Box>
        )}
      </Container>
    </>
  );
};

export default SearchGyms; 