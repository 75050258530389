import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  Avatar,
  Rating,
} from '@mui/material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DevicesIcon from '@mui/icons-material/Devices';
import padelImage from '../images/padel.jpeg';
import SignupForm from '../components/SignupForm';

const Individual = () => {
  const { t, i18n } = useTranslation();
  const [openSignupForm, setOpenSignupForm] = useState(false);
  
  const handleOpenSignupForm = () => {
    setOpenSignupForm(true);
  };
  
  const handleCloseSignupForm = () => {
    setOpenSignupForm(false);
  };
  
  // Use translated benefits if available, otherwise use hardcoded Spanish values
  const featureIcons = [
    <FitnessCenterIcon fontSize="large" />,
    <AccessTimeIcon fontSize="large" />,
    <LocationOnIcon fontSize="large" />,
    <DevicesIcon fontSize="large" />,
  ];
  
  const features = i18n.language === 'en' && t('pages.individual.benefits', { returnObjects: true })
    ? t('pages.individual.benefits', { returnObjects: true }).map((benefit, index) => ({
        icon: featureIcons[index],
        title: benefit.title,
        description: benefit.description,
      }))
    : [
        {
          icon: <FitnessCenterIcon fontSize="large" />,
          title: 'Acceso a múltiples gimnasios',
          description: 'Entrena donde quieras con acceso a más de 1,000 gimnasios.',
        },
        {
          icon: <AccessTimeIcon fontSize="large" />,
          title: 'Flexibilidad total',
          description: 'Sin permanencia ni matrículas. Cancela cuando quieras sin compromiso.',
        },
        {
          icon: <LocationOnIcon fontSize="large" />,
          title: 'Entrena donde estés',
          description: 'Ya sea en tu barrio, cerca del trabajo o de viaje, siempre tendrás un gimnasio cerca.',
        },
        {
          icon: <DevicesIcon fontSize="large" />,
          title: 'App móvil intuitiva',
          description: 'Reserva clases, busca gimnasios y gestiona tu cuenta desde cualquier dispositivo.',
        },
      ];
  
  const testimonials = i18n.language === 'en' && t('pages.individual.testimonials.items', { returnObjects: true })
    ? t('pages.individual.testimonials.items', { returnObjects: true })
    : [
        {
          name: 'Laura Martínez',
          text: 'TuttoGym ha cambiado completamente mi forma de entrenar. Puedo ir al gimnasio cerca de mi trabajo entre semana y cerca de casa los fines de semana.',
          rating: 5,
        },
        {
          name: 'Carlos Rodríguez',
          text: 'Me encanta la flexibilidad que ofrece. He descubierto actividades que nunca hubiera probado antes y ahora son mis favoritas.',
          rating: 5,
        },
        {
          name: 'María González',
          text: 'La relación calidad-precio es inmejorable. Accedo a gimnasios premium por menos de lo que pagaba antes en un solo centro.',
          rating: 4,
        },
      ];
  
  const featureItems = i18n.language === 'en' && t('pages.individual.features.items', { returnObjects: true })
    ? t('pages.individual.features.items', { returnObjects: true })
    : [
        'Acceso a más de 1,000 gimnasios',
        'Reserva clases al instante',
        'Sin matrícula',
        'Lleva invitados contigo',
        'Recomendaciones personalizadas',
        'Soporte 24/7',
      ];
  
  return (
    <>
      <Helmet>
        <title>{i18n.language === 'en' ? 'Individual Plans | TuttoGym' : 'Planes Individuales | TuttoGym'}</title>
        <meta name="description" content={i18n.language === 'en' 
          ? 'Access more than 1,000 gyms and activities with a single membership. Flexible plans starting at €29.99/month.'
          : 'Accede a más de 1,000 gimnasios y actividades con una sola membresía. Planes flexibles desde 29,99€/mes.'} />
      </Helmet>
      
      {/* SignupForm Dialog */}
      <SignupForm open={openSignupForm} onClose={handleCloseSignupForm} />
      
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          bgcolor: 'primary.main',
          color: 'white',
          py: 8,
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom>
                {i18n.language === 'en' && t('pages.individual.hero.title')
                  ? t('pages.individual.hero.title')
                  : 'Tu viaje fitness comienza aquí'}
              </Typography>
              <Typography variant="h6" paragraph>
                {i18n.language === 'en' && t('pages.individual.hero.subtitle')
                  ? t('pages.individual.hero.subtitle')
                  : 'Accede a más de 1,000 gimnasios y actividades con una sola membresía'}
              </Typography>
              <Button 
                variant="contained" 
                color="secondary" 
                size="large"
                sx={{ 
                  mt: 2,
                  color: 'white',
                  px: 4,
                  py: 1.5,
                }}
                onClick={handleOpenSignupForm}
              >
                {i18n.language === 'en' && t('pages.individual.hero.button')
                  ? t('pages.individual.hero.button')
                  : 'Comenzar ahora'}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={padelImage}
                alt={i18n.language === 'en' ? 'Person exercising' : 'Persona haciendo ejercicio'}
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: '0 10px 30px rgba(0,0,0,0.2)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Features Section */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Box className="section-title" sx={{ textAlign: 'center', mb: 6 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              {i18n.language === 'en' && t('pages.individual.features.title')
                ? t('pages.individual.features.title')
                : '¿Por qué elegir TuttoGym?'}
            </Typography>
            <Typography variant="body1">
              {i18n.language === 'en' && t('pages.individual.features.subtitle')
                ? t('pages.individual.features.subtitle')
                : 'La forma inteligente de mantenerse activo'}
            </Typography>
          </Box>
          
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
                  <Box sx={{ color: 'primary.main', mb: 2 }}>
                    {feature.icon}
                  </Box>
                  <Typography variant="h6" component="h3" align="center" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" align="center">
                    {feature.description}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
          
          <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined" color="primary" size="large">
              {i18n.language === 'en' ? 'View all features' : 'Ver todas las características'}
            </Button>
          </Box>
        </Container>
      </Box>
      
      {/* Benefits List Section */}
      <Box sx={{ py: 8, bgcolor: 'background.default' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h2" gutterBottom>
                {i18n.language === 'en' ? 'Everything you need in one membership' : 'Todo lo que necesitas en una membresía'}
              </Typography>
              <Typography variant="body1" paragraph>
                {i18n.language === 'en' 
                  ? 'TuttoGym offers the most complete fitness experience with benefits designed for your lifestyle.'
                  : 'TuttoGym ofrece la experiencia fitness más completa con beneficios diseñados para tu estilo de vida.'}
              </Typography>
              
              <Box component="ul" sx={{ pl: 2 }}>
                {featureItems.map((item, index) => (
                  <Box 
                    component="li" 
                    key={index}
                    sx={{ 
                      typography: 'body1',
                      mb: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box 
                      component="span" 
                      sx={{ 
                        display: 'inline-block',
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        bgcolor: 'primary.main',
                        mr: 2,
                        flexShrink: 0,
                      }} 
                    />
                    {item}
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/images/fitness-benefits.jpg"
                alt={i18n.language === 'en' ? 'Fitness benefits' : 'Beneficios fitness'}
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Testimonials Section */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Box className="section-title" sx={{ textAlign: 'center', mb: 6 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              {i18n.language === 'en' && t('pages.individual.testimonials.title')
                ? t('pages.individual.testimonials.title')
                : 'Lo que dicen nuestros miembros'}
            </Typography>
          </Box>
          
          <Grid container spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 3 }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" paragraph sx={{ fontStyle: 'italic' }}>
                      "{testimonial.text}"
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                        {testimonial.name.charAt(0)}
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle1" component="p">
                          {testimonial.name}
                        </Typography>
                        <Rating value={testimonial.rating || 5} readOnly size="small" />
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      
      {/* CTA Section */}
      <Box sx={{ py: 8, bgcolor: 'secondary.main', color: 'white' }}>
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            {i18n.language === 'en' ? 'Ready to transform your fitness routine?' : '¿Listo para transformar tu rutina fitness?'}
          </Typography>
          <Typography variant="body1" paragraph>
            {i18n.language === 'en'
              ? 'Join thousands of members who are already enjoying unlimited access to the best gyms and activities.'
              : 'Únete a miles de miembros que ya disfrutan de acceso ilimitado a los mejores gimnasios y actividades.'}
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            size="large"
            sx={{ 
              mt: 2,
              bgcolor: 'white',
              color: 'secondary.main',
              '&:hover': {
                bgcolor: 'grey.100',
              }
            }}
            onClick={handleOpenSignupForm}
          >
            {i18n.language === 'en' ? 'Start your free trial' : 'Comienza tu prueba gratuita'}
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default Individual; 