class NotificationService {
  constructor() {
    this.permission = null;
    this.init();
  }

  init() {
    // Verificar si el navegador soporta notificaciones
    if (!('Notification' in window)) {
      console.warn('Este navegador no soporta notificaciones de escritorio');
      return;
    }

    // Verificar si ya tenemos permiso
    if (Notification.permission === 'granted') {
      this.permission = 'granted';
    } else if (Notification.permission !== 'denied') {
      // Solicitar permiso
      Notification.requestPermission().then(permission => {
        this.permission = permission;
      });
    }
  }

  // Mostrar una notificación
  showNotification(title, options = {}, onClick = null) {
    if (this.permission !== 'granted') {
      console.warn('No se tienen permisos para mostrar notificaciones');
      return;
    }

    const defaultOptions = {
      icon: '/favicon.ico',
      badge: '/favicon.ico',
      vibrate: [200, 100, 200],
      ...options
    };

    const notification = new Notification(title, defaultOptions);

    if (onClick) {
      notification.onclick = onClick;
    }

    return notification;
  }

  // Mostrar notificación de nuevo check-in
  showCheckInNotification(checkin, onViewClick, onApproveClick) {
    const options = {
      body: `${checkin.userName} ha realizado un check-in`,
      icon: '/favicon.ico',
      actions: [
        { action: 'view', title: 'Ver' },
        { action: 'approve', title: 'Aprobar' }
      ],
      requireInteraction: true
    };

    const notification = this.showNotification('Nuevo Check-in', options, () => {
      window.focus();
      if (onViewClick) onViewClick(checkin);
    });

    if (notification) {
      notification.addEventListener('notificationclick', (event) => {
        event.notification.close();
        
        if (event.action === 'view' && onViewClick) {
          onViewClick(checkin);
        } else if (event.action === 'approve' && onApproveClick) {
          onApproveClick(checkin.id);
        }
      });
    }
  }
}

// Exportar una instancia única del servicio
export default new NotificationService(); 