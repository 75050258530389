import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Divider,
  Button,
  IconButton,
  Stack,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const Footer = () => {
  const { t } = useTranslation();
  
  const footerLinks = [
    {
      title: 'TuttoGym',
      links: [
        { name: t('header.searchGyms'), path: '/search-gyms' },
        { name: t('header.business'), path: '/business' },
        { name: t('header.individual'), path: '/individual' },
        { name: t('header.partners'), path: '/partners' },
        { name: t('header.plansAndPrices'), path: '/plans-and-prices' },
      ],
    },
    {
      title: t('footer.apps'),
      links: [
        { name: 'iOS App', path: '#', icon: <AppleIcon /> },
        { name: 'Android App', path: '#', icon: <AndroidIcon /> },
      ],
    },
    {
      title: t('footer.social'),
      links: [
        { name: 'Facebook', path: '#', icon: <FacebookIcon /> },
        { name: 'X', path: '#', icon: <TwitterIcon /> },
        { name: 'Instagram', path: '#', icon: <InstagramIcon /> },
        { name: 'TikTok', path: '#', icon: <MusicNoteIcon /> },
        { name: 'LinkedIn', path: '#', icon: <LinkedInIcon /> },
        { name: 'YouTube', path: '#', icon: <YouTubeIcon /> },
      ],
    },
    {
      title: 'Legal',
      links: [
        { name: t('footer.privacy'), path: '/privacy' },
        { name: t('footer.cookies'), path: '/cookies' },
        { name: t('footer.terms'), path: '/terms' },
        { name: t('footer.security'), path: '/security' },
      ],
    },
  ];
  
  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          {footerLinks.map((section) => (
            <Grid item xs={12} sm={6} md={3} key={section.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {section.title}
              </Typography>
              <Box>
                {section.links.map((link) => (
                  <Box key={link.name} sx={{ mb: 1 }}>
                    <Link
                      component={RouterLink}
                      to={link.path}
                      color="text.secondary"
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        '&:hover': { color: 'primary.main' }
                      }}
                    >
                      {link.icon && (
                        <Box component="span" sx={{ mr: 1, display: 'flex' }}>
                          {link.icon}
                        </Box>
                      )}
                      {link.name}
                    </Link>
                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
        
        <Divider sx={{ mt: 6, mb: 4 }} />
        
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: { xs: 2, sm: 0 } }}>
            © {new Date().getFullYear()} TuttoGym. Todos los derechos reservados.
          </Typography>
          
          <Stack direction="row" spacing={1}>
            <IconButton size="small" color="inherit" aria-label="facebook">
              <FacebookIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" color="inherit" aria-label="x">
              <TwitterIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" color="inherit" aria-label="instagram">
              <InstagramIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" color="inherit" aria-label="tiktok">
              <MusicNoteIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" color="inherit" aria-label="linkedin">
              <LinkedInIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer; 