import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import CssBaseline from '@mui/material/CssBaseline';
import './i18n';
import theme from './styles/theme';
import './styles/global.css';

// Contextos
import { CityProvider } from './contexts/CityContext';

// Componentes
import Header from './components/Header';
import Footer from './components/Footer';

// Páginas
import Home from './pages/Home';
import SearchGyms from './pages/SearchGyms';
import Business from './pages/Business';
import Individual from './pages/Individual';
import Partners from './pages/Partners';
import PlansAndPrices from './pages/PlansAndPrices';
import Panel from './pages/panel';
import CheckInSimulator from './pages/CheckInSimulator';

function App() {
  // Determinar si la ruta actual es parte del panel de administración
  const isAdminPanel = () => {
    return window.location.pathname.startsWith('/panel');
  };

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CityProvider>
          <CssBaseline />
          <Router>
            {/* Mostrar Header y Footer solo si no estamos en el panel de administración */}
            {!isAdminPanel() && <Header />}
            <main>
              <Routes>
                {/* Rutas públicas */}
                <Route path="/" element={<Home />} />
                <Route path="/search-gyms" element={<SearchGyms />} />
                <Route path="/business" element={<Business />} />
                <Route path="/individual" element={<Individual />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/plans-and-prices" element={<PlansAndPrices />} />
                <Route path="/check-in" element={<CheckInSimulator />} />
                
                {/* Rutas del panel de administración */}
                <Route path="/panel/*" element={<Panel />} />
              </Routes>
            </main>
            {!isAdminPanel() && <Footer />}
          </Router>
        </CityProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App; 