import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { isAuthenticated } from '../services/api';

const ProtectedRoute = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Verificar si el usuario está autenticado
    const checkAuth = () => {
      const authorized = isAuthenticated();
      setIsAuthorized(authorized);
      setAuthChecked(true);
    };
    
    checkAuth();
  }, []);

  // Mientras se verifica la autenticación, mostrar un spinner
  if (!authChecked) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Si no está autenticado, redirigir al login
  if (!isAuthorized) {
    return <Navigate to="/panel" state={{ from: location }} replace />;
  }

  // Si está autenticado, mostrar el contenido protegido
  return children;
};

export default ProtectedRoute; 