import React, { createContext, useState, useContext } from 'react';

const CityContext = createContext();

// Cities configuration with currency information
export const CITIES = {
  BARCELONA: {
    id: 'barcelona',
    name: 'Barcelona',
    flag: '🇪🇸',
    country: 'Spain',
    currency: 'EUR',
    currencySymbol: '€',
    // Rate compared to EUR (1 EUR = 1 EUR)
    exchangeRate: 1
  },
  SANTIAGO: {
    id: 'santiago',
    name: 'Santiago de Chile',
    flag: '🇨🇱',
    country: 'Chile',
    currency: 'CLP',
    currencySymbol: '$',
    // Example rate: 1 EUR ≈ 1000 CLP (this should be updated with correct rate)
    exchangeRate: 1000
  }
};

export function CityProvider({ children }) {
  // Default city is Barcelona
  const [currentCity, setCurrentCity] = useState(CITIES.BARCELONA);

  const changeCity = (cityId) => {
    const city = Object.values(CITIES).find(city => city.id === cityId);
    if (city) {
      setCurrentCity(city);
    }
  };

  // Convert price from EUR to the selected city's currency
  const convertPrice = (priceInEur) => {
    // Handle comma as decimal separator (common in European format)
    const normalizedPrice = typeof priceInEur === 'string' 
      ? parseFloat(priceInEur.replace(',', '.')) 
      : priceInEur;
    
    if (isNaN(normalizedPrice)) return priceInEur; // Return original if not a valid number

    // Convert to the selected city's currency
    const convertedPrice = normalizedPrice * currentCity.exchangeRate;
    
    // Format based on currency
    if (currentCity.currency === 'EUR') {
      return convertedPrice.toFixed(2).replace('.', ',');
    } else if (currentCity.currency === 'CLP') {
      // Chilean Peso typically doesn't use decimals
      return Math.round(convertedPrice).toLocaleString();
    }
    
    return convertedPrice.toString();
  };

  return (
    <CityContext.Provider value={{ currentCity, changeCity, convertPrice, CITIES }}>
      {children}
    </CityContext.Provider>
  );
}

export function useCity() {
  return useContext(CityContext);
} 