import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Paper,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GroupsIcon from '@mui/icons-material/Groups';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import gymImage from '../images/gym.jpg';
import partnerDashboardImage from '../images/partner-dashboard.jpg';
import RequestInfoForm from '../components/RequestInfoForm';

const Partners = () => {
  const { t, i18n } = useTranslation();
  const [openPartnerForm, setOpenPartnerForm] = useState(false);
  
  // Use translated benefits if available, otherwise use hardcoded Spanish values
  const benefitIcons = [
    <MonetizationOnIcon fontSize="large" color="primary" />,
    <GroupsIcon fontSize="large" color="primary" />,
    <VisibilityIcon fontSize="large" color="primary" />,
    <TrendingUpIcon fontSize="large" color="primary" />,
  ];
  
  const benefits = i18n.language === 'en' && t('pages.partners.benefits', { returnObjects: true })
    ? t('pages.partners.benefits', { returnObjects: true }).map((benefit, index) => ({
        ...benefit,
        icon: benefitIcons[index],
      }))
    : [
        {
          title: 'Aumenta tus ingresos',
          description: 'Genera nuevas fuentes de ingresos y maximiza la ocupación de tu gimnasio.',
          icon: <MonetizationOnIcon fontSize="large" color="primary" />,
        },
        {
          title: 'Atrae nuevos clientes',
          description: 'Accede a miles de usuarios potenciales que buscan nuevas experiencias fitness.',
          icon: <GroupsIcon fontSize="large" color="primary" />,
        },
        {
          title: 'Aumenta tu visibilidad',
          description: 'Destaca tu gimnasio en nuestra plataforma y llega a más personas.',
          icon: <VisibilityIcon fontSize="large" color="primary" />,
        },
        {
          title: 'Crece con nosotros',
          description: 'Forma parte de la red fitness más grande de España y aprovecha nuestro crecimiento.',
          icon: <TrendingUpIcon fontSize="large" color="primary" />,
        },
      ];
  
  const steps = i18n.language === 'en' && t('pages.partners.howItWorks.steps', { returnObjects: true })
    ? t('pages.partners.howItWorks.steps', { returnObjects: true })
    : [
        {
          title: 'Únete a nuestra red',
          description: 'Regístrate como partner de TuttoGym y configura el perfil de tu gimnasio con fotos, servicios y actividades disponibles.',
        },
        {
          title: 'Define tu disponibilidad',
          description: 'Establece cuándo pueden visitar tu instalación los miembros de TuttoGym según tu capacidad y horas pico.',
        },
        {
          title: 'Recibe nuevos clientes',
          description: 'Los miembros de TuttoGym hacen check-in usando nuestra app, y tú verificas su identidad con un simple escaneo de código QR.',
        },
        {
          title: 'Recibe pagos',
          description: 'Recibe pagos mensuales por todas las visitas de TuttoGym a tu instalación, con informes detallados y análisis.',
        },
      ];
  
  const handleOpenPartnerForm = () => {
    setOpenPartnerForm(true);
  };

  const handleClosePartnerForm = () => {
    setOpenPartnerForm(false);
  };
  
  return (
    <>
      <Helmet>
        <title>{i18n.language === 'en' ? 'Partner with TuttoGym' : 'Conviértete en Partner | TuttoGym'}</title>
        <meta name="description" content={i18n.language === 'en' 
          ? 'Join our network of gyms and fitness centers. Increase your revenue and attract new customers with TuttoGym.'
          : 'Únete a nuestra red de gimnasios y centros fitness. Aumenta tus ingresos y atrae nuevos clientes con TuttoGym.'} />
      </Helmet>
      
      {/* Hero Section */}
      <Box
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          py: 8,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom>
                {i18n.language === 'en' && t('pages.partners.hero.title')
                  ? t('pages.partners.hero.title')
                  : 'Haz crecer tu negocio con TuttoGym'}
              </Typography>
              <Typography variant="h6" paragraph>
                {i18n.language === 'en' && t('pages.partners.hero.subtitle')
                  ? t('pages.partners.hero.subtitle')
                  : 'Únete a nuestra red de gimnasios y centros fitness y llega a miles de nuevos clientes'}
              </Typography>
              <Button 
                variant="contained" 
                color="secondary" 
                size="large"
                onClick={handleOpenPartnerForm}
                sx={{ 
                  mt: 2,
                  color: 'white',
                  px: 4,
                  py: 1.5,
                }}
              >
                {i18n.language === 'en' && t('pages.partners.hero.button')
                  ? t('pages.partners.hero.button')
                  : 'Convertirse en partner'}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={gymImage}
                alt={i18n.language === 'en' ? 'Modern gym' : 'Gimnasio moderno'}
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: '0 10px 30px rgba(0,0,0,0.2)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Benefits Section */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Box className="section-title">
            <Typography variant="h4" component="h2">
              {i18n.language === 'en' ? 'Benefits of partnering with us' : 'Beneficios de ser partner'}
            </Typography>
            <Typography variant="body1">
              {i18n.language === 'en'
                ? 'Discover how TuttoGym can help your fitness business grow'
                : 'Descubre cómo TuttoGym puede ayudar a crecer a tu negocio fitness'}
            </Typography>
          </Box>
          
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {benefits.map((benefit, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {benefit.icon}
                      <Typography variant="h6" component="h3" sx={{ ml: 2 }}>
                        {benefit.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="text.secondary">
                      {benefit.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      
      {/* How it works */}
      <Box sx={{ py: 8, bgcolor: 'background.default' }}>
        <Container maxWidth="lg">
          <Box className="section-title">
            <Typography variant="h4" component="h2">
              {i18n.language === 'en' && t('pages.partners.howItWorks.title')
                ? t('pages.partners.howItWorks.title')
                : 'Cómo funciona'}
            </Typography>
            <Typography variant="body1">
              {i18n.language === 'en'
                ? 'Simple steps to start growing your business with TuttoGym'
                : 'Pasos sencillos para empezar a crecer tu negocio con TuttoGym'}
            </Typography>
          </Box>
          
          <Box sx={{ mt: 6 }}>
            {steps.map((step, index) => (
              <Paper 
                key={index}
                elevation={1}
                sx={{ 
                  p: 3, 
                  mb: 3,
                  borderLeft: '4px solid',
                  borderColor: 'primary.main',
                }}
              >
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={1}>
                    <Box 
                      sx={{ 
                        width: 50, 
                        height: 50, 
                        borderRadius: '50%', 
                        bgcolor: 'primary.main', 
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        fontSize: '1.25rem',
                      }}
                    >
                      {index + 1}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <Typography variant="h6" gutterBottom>
                      {step.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {step.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Box>
        </Container>
      </Box>
      
      {/* Features Section */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h2" gutterBottom>
                {i18n.language === 'en' ? 'Everything you need to succeed' : 'Todo lo que necesitas para tener éxito'}
              </Typography>
              <Typography variant="body1" paragraph>
                {i18n.language === 'en'
                  ? 'Our platform provides all the tools and support you need to maximize your business potential.'
                  : 'Nuestra plataforma proporciona todas las herramientas y el soporte que necesitas para maximizar el potencial de tu negocio.'}
              </Typography>
              
              <List>
                {[
                  i18n.language === 'en' ? 'Dedicated partner dashboard' : 'Panel de control dedicado para partners',
                  i18n.language === 'en' ? 'Real-time analytics and reporting' : 'Análisis y reportes en tiempo real',
                  i18n.language === 'en' ? 'Marketing support and promotion' : 'Soporte de marketing y promoción',
                  i18n.language === 'en' ? 'Flexible payment options' : 'Opciones de pago flexibles',
                  i18n.language === 'en' ? 'Technical support and training' : 'Soporte técnico y formación',
                ].map((feature, index) => (
                  <ListItem key={index} sx={{ py: 1 }}>
                    <ListItemIcon>
                      <CheckCircleIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={partnerDashboardImage}
                alt={i18n.language === 'en' ? 'Partner dashboard' : 'Panel de control para partners'}
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* CTA Section */}
      <Box sx={{ py: 8, bgcolor: 'secondary.main', color: 'white' }}>
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            {i18n.language === 'en' && t('pages.partners.cta.title')
              ? t('pages.partners.cta.title')
              : '¿Listo para expandir tu base de clientes?'}
          </Typography>
          <Typography variant="body1" paragraph>
            {i18n.language === 'en' && t('pages.partners.cta.subtitle')
              ? t('pages.partners.cta.subtitle')
              : 'Únete a más de 1,000 instalaciones fitness que ya son partners de TuttoGym'}
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            size="large"
            onClick={handleOpenPartnerForm}
            sx={{ 
              mt: 2,
              bgcolor: 'white',
              color: 'secondary.main',
              '&:hover': {
                bgcolor: 'grey.100',
              }
            }}
          >
            {i18n.language === 'en' && t('pages.partners.cta.button')
              ? t('pages.partners.cta.button')
              : 'Solicitar información'}
          </Button>
        </Container>
      </Box>
      
      {/* Partner Form Dialog */}
      <RequestInfoForm 
        open={openPartnerForm} 
        onClose={handleClosePartnerForm} 
        formType="partner"
      />
    </>
  );
};

export default Partners; 