import React, { useState } from 'react';
import { 
  Button, 
  Menu, 
  MenuItem, 
  ListItemIcon, 
  ListItemText 
} from '@mui/material';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCity, CITIES } from '../contexts/CityContext';

const CitySelector = () => {
  const { currentCity, changeCity } = useCity();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleCityChange = (cityId) => {
    changeCity(cityId);
    handleClose();
  };
  
  const cities = Object.values(CITIES);
  
  return (
    <>
      <Button
        id="city-button"
        aria-controls={open ? 'city-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<LocationCityIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ 
          textTransform: 'none',
          color: 'text.primary',
          ml: 2
        }}
      >
        {currentCity.flag} {currentCity.name}
      </Button>
      <Menu
        id="city-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'city-button',
        }}
      >
        {cities.map((city) => (
          <MenuItem 
            key={city.id} 
            onClick={() => handleCityChange(city.id)}
            selected={currentCity.id === city.id}
          >
            <ListItemIcon sx={{ fontSize: '1.2rem', minWidth: '30px' }}>
              {city.flag}
            </ListItemIcon>
            <ListItemText>{city.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CitySelector; 