import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import workoutImage from '../images/workout.jpg';
import corporateDashboardImage from '../images/corporate-dashboard.jpg';
import RequestInfoForm from '../components/RequestInfoForm';

const Business = () => {
  const { t, i18n } = useTranslation();
  const [openRequestForm, setOpenRequestForm] = useState(false);
  const [formType, setFormType] = useState('info'); // 'info' or 'advisor'
  
  // Use translated benefits if available, otherwise use hardcoded Spanish values
  const benefitsIcons = [
    <HealthAndSafetyIcon fontSize="large" color="primary" />,
    <PeopleIcon fontSize="large" color="primary" />,
    <TrendingUpIcon fontSize="large" color="primary" />,
    <BusinessIcon fontSize="large" color="primary" />,
  ];
  
  const benefits = i18n.language === 'en' && t('pages.business.benefits', { returnObjects: true }) 
    ? t('pages.business.benefits', { returnObjects: true }).map((benefit, index) => ({
        ...benefit,
        icon: benefitsIcons[index]
      }))
    : [
        {
          title: 'Mejora la salud y bienestar de tus empleados',
          description: 'Ofrece acceso a más de 1,000 gimnasios y actividades para promover un estilo de vida saludable.',
          icon: <HealthAndSafetyIcon fontSize="large" color="primary" />,
        },
        {
          title: 'Reduce el absentismo laboral',
          description: 'Los empleados activos tienen un 27% menos de días de baja por enfermedad.',
          icon: <PeopleIcon fontSize="large" color="primary" />,
        },
        {
          title: 'Aumenta la productividad',
          description: 'El ejercicio regular mejora la concentración y el rendimiento en el trabajo.',
          icon: <TrendingUpIcon fontSize="large" color="primary" />,
        },
        {
          title: 'Atrae y retiene talento',
          description: 'El 87% de los empleados considera los beneficios de bienestar al elegir un empleador.',
          icon: <BusinessIcon fontSize="large" color="primary" />,
        },
      ];
  
  const features = i18n.language === 'en' && t('pages.business.features', { returnObjects: true })
    ? t('pages.business.features', { returnObjects: true })
    : [
        'Portal de administración personalizado',
        'Informes de uso y participación',
        'Soporte dedicado para empresas',
        'Facturación centralizada',
        'Personalización de planes según necesidades',
        'Integración con sistemas de RRHH',
        'Comunicación interna de beneficios',
        'Eventos corporativos exclusivos',
      ];
  
  const handleOpenRequestForm = (type) => {
    setFormType(type);
    setOpenRequestForm(true);
  };

  const handleCloseRequestForm = () => {
    setOpenRequestForm(false);
  };
  
  return (
    <>
      <Helmet>
        <title>{i18n.language === 'en' ? 'Business Solutions | TuttoGym' : 'Soluciones para Empresas | TuttoGym'}</title>
        <meta name="description" content={i18n.language === 'en' 
          ? 'Improve your employees\' well-being with TuttoGym. Offer access to more than 1,000 gyms and activities as a corporate benefit.'
          : 'Mejora el bienestar de tus empleados con TuttoGym. Ofrece acceso a más de 1,000 gimnasios y actividades como beneficio corporativo.'} />
      </Helmet>
      
      {/* Hero Section */}
      <Box
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          py: 8,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom>
                {i18n.language === 'en' ? 'Corporate Wellness with TuttoGym' : 'Bienestar corporativo con TuttoGym'}
              </Typography>
              <Typography variant="h6" paragraph>
                {i18n.language === 'en' 
                  ? 'Improve the health, productivity, and satisfaction of your employees with access to the largest network of gyms and activities.'
                  : 'Mejora la salud, productividad y satisfacción de tus empleados con acceso a la red más grande de gimnasios y actividades.'}
              </Typography>
              <Button 
                variant="contained" 
                color="secondary" 
                size="large"
                onClick={() => handleOpenRequestForm('info')}
                sx={{ 
                  mt: 2,
                  color: 'white',
                  px: 4,
                  py: 1.5,
                }}
              >
                {i18n.language === 'en' ? 'Request Information' : 'Solicitar información'}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={workoutImage}
                alt={i18n.language === 'en' ? 'People exercising' : 'Personas haciendo ejercicio'}
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: '0 10px 30px rgba(0,0,0,0.2)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Benefits Section */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Box className="section-title">
            <Typography variant="h4" component="h2">
              {i18n.language === 'en' ? 'Benefits for your company' : 'Beneficios para tu empresa'}
            </Typography>
            <Typography variant="body1">
              {i18n.language === 'en' 
                ? 'Discover how TuttoGym can transform your organization\'s well-being'
                : 'Descubre cómo TuttoGym puede transformar el bienestar de tu organización'}
            </Typography>
          </Box>
          
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {benefits.map((benefit, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {benefit.icon}
                      <Typography variant="h6" component="h3" sx={{ ml: 2 }}>
                        {benefit.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="text.secondary">
                      {benefit.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      
      {/* Features Section */}
      <Box sx={{ py: 8, bgcolor: 'background.default' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h2" gutterBottom>
                {i18n.language === 'en' ? 'Exclusive features for businesses' : 'Características exclusivas para empresas'}
              </Typography>
              <Typography variant="body1" paragraph>
                {i18n.language === 'en'
                  ? 'Our corporate solutions are designed to adapt to the specific needs of your organization, regardless of its size.'
                  : 'Nuestras soluciones corporativas están diseñadas para adaptarse a las necesidades específicas de tu organización, independientemente de su tamaño.'}
              </Typography>
              
              <List>
                {features.map((feature, index) => (
                  <ListItem key={index} sx={{ py: 1 }}>
                    <ListItemIcon>
                      <CheckCircleIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={corporateDashboardImage}
                alt={i18n.language === 'en' ? 'Corporate dashboard' : 'Dashboard corporativo'}
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* CTA Section */}
      <Box sx={{ py: 8, bgcolor: 'secondary.main', color: 'white' }}>
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            {i18n.language === 'en' && t('pages.business.cta.title')
              ? t('pages.business.cta.title')
              : '¿Listo para mejorar el bienestar de tu equipo?'}
          </Typography>
          <Typography variant="body1" paragraph>
            {i18n.language === 'en' && t('pages.business.cta.subtitle')
              ? t('pages.business.cta.subtitle')
              : 'Únete a más de 500 empresas que ya confían en TuttoGym para sus programas de bienestar corporativo.'}
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            size="large"
            onClick={() => handleOpenRequestForm('advisor')}
            sx={{ 
              mt: 2,
              bgcolor: 'white',
              color: 'secondary.main',
              '&:hover': {
                bgcolor: 'grey.100',
              }
            }}
          >
            {i18n.language === 'en' && t('pages.business.cta.button')
              ? t('pages.business.cta.button')
              : 'Contactar con un asesor'}
          </Button>
        </Container>
      </Box>
      
      {/* Request Information Form Dialog */}
      <RequestInfoForm 
        open={openRequestForm} 
        onClose={handleCloseRequestForm}
        formType={formType}
      />
    </>
  );
};

export default Business; 