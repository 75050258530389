import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  useTheme,
} from '@mui/material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SportsIcon from '@mui/icons-material/Sports';
import BusinessIcon from '@mui/icons-material/Business';

const Benefits = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  const benefits = [
    {
      title: t('benefits.allGyms'),
      description: t('benefits.descriptions.gyms'),
      icon: <FitnessCenterIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />,
      count: '1,000+',
    },
    {
      title: t('benefits.allActivities'),
      description: t('benefits.descriptions.activities'),
      icon: <SportsIcon sx={{ fontSize: 60, color: theme.palette.secondary.main }} />,
      count: '50+',
    },
    {
      title: t('benefits.registeredBusinesses'),
      description: t('benefits.descriptions.businesses'),
      icon: <BusinessIcon sx={{ fontSize: 60, color: theme.palette.success.main }} />,
      count: '500+',
    },
  ];
  
  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Box className="section-title">
          <Typography variant="h2" component="h2">
            {t('benefits.title')}
          </Typography>
          <Typography variant="body1">
            {t('benefits.subtitle')}
          </Typography>
        </Box>
        
        <Grid container spacing={4} sx={{ mt: 2 }}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card 
                sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 3,
                }}
              >
                <Box sx={{ mb: 2 }}>
                  {benefit.icon}
                </Box>
                <Typography variant="h3" component="h3" sx={{ fontSize: '2.5rem', fontWeight: 700, mb: 1 }}>
                  {benefit.count}
                </Typography>
                <Typography variant="h5" component="h4" sx={{ mb: 2, fontWeight: 600 }}>
                  {benefit.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {benefit.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Benefits; 