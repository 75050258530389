import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Tabs,
  Tab,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import ModalPortal from './ModalPortal';

const AuthModal = ({ open, onClose, mode }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(mode === 'login' ? 0 : 1);
  const [userType, setUserType] = useState('client');
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    // Aquí iría la lógica de autenticación
    onClose();
  };
  
  return (
    <ModalPortal open={open}>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            px: { xs: 2, sm: 4 },
            py: 2
          }
        }}
        container={document.getElementById('modal-root')}
        disableEnforceFocus
        hideBackdrop={false}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
          <Typography variant="h5" component="div" fontWeight="bold">
            {activeTab === 0 ? t('header.login') : t('header.register')}
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label={t('header.login')} />
            <Tab label={t('header.register')} />
          </Tabs>
        </Box>
        
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            {activeTab === 1 && (
              <FormControl component="fieldset" sx={{ mb: 3, width: '100%' }}>
                <FormLabel component="legend">Tipo de cuenta</FormLabel>
                <RadioGroup
                  row
                  name="user-type"
                  value={userType}
                  onChange={handleUserTypeChange}
                >
                  <FormControlLabel value="client" control={<Radio />} label={t('auth.client')} />
                  <FormControlLabel value="business" control={<Radio />} label={t('auth.business')} />
                  <FormControlLabel value="partner" control={<Radio />} label={t('auth.partner')} />
                </RadioGroup>
              </FormControl>
            )}
            
            {activeTab === 1 && (
              <>
                <TextField
                  margin="dense"
                  label="Nombre"
                  type="text"
                  fullWidth
                  variant="outlined"
                  required
                  sx={{ mb: 2 }}
                />
                <TextField
                  margin="dense"
                  label="Apellidos"
                  type="text"
                  fullWidth
                  variant="outlined"
                  required
                  sx={{ mb: 2 }}
                />
              </>
            )}
            
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              required
              sx={{ mb: 2 }}
            />
            
            <TextField
              margin="dense"
              label="Contraseña"
              type="password"
              fullWidth
              variant="outlined"
              required
              sx={{ mb: 2 }}
            />
            
            {activeTab === 1 && (
              <TextField
                margin="dense"
                label="Confirmar contraseña"
                type="password"
                fullWidth
                variant="outlined"
                required
                sx={{ mb: 2 }}
              />
            )}
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 2, mb: 2, py: 1.5 }}
            >
              {activeTab === 0 ? t('header.login') : t('header.register')}
            </Button>
            
            {activeTab === 0 && (
              <Typography variant="body2" align="center" sx={{ mb: 2 }}>
                <Button color="primary" sx={{ textTransform: 'none', fontWeight: 'normal' }}>
                  ¿Olvidaste tu contraseña?
                </Button>
              </Typography>
            )}
            
            <Divider sx={{ my: 2 }}>
              <Typography variant="body2" color="text.secondary">
                O continúa con
              </Typography>
            </Divider>
            
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
              <Button variant="outlined" startIcon={<GoogleIcon />} sx={{ borderRadius: 2 }}>
                Google
              </Button>
              <Button variant="outlined" startIcon={<FacebookIcon />} sx={{ borderRadius: 2 }}>
                Facebook
              </Button>
              <Button variant="outlined" startIcon={<AppleIcon />} sx={{ borderRadius: 2 }}>
                Apple
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </ModalPortal>
  );
};

export default AuthModal; 