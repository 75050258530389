import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

// Component to render content inside a portal outside the main React tree
// This prevents aria-hidden issues and improves accessibility
const ModalPortal = ({ children, open }) => {
  const modalRoot = document.getElementById('modal-root');
  
  // Only render the portal when the modal is open
  if (!open || !modalRoot) {
    return null;
  }
  
  return createPortal(children, modalRoot);
};

export default ModalPortal; 