import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageSwitcher from './LanguageSwitcher';
import CitySelector from './CitySelector';
import AuthModal from './AuthModal';

const Header = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [mobileOpen, setMobileOpen] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [authMode, setAuthMode] = useState('login'); // 'login' o 'register'
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const handleAuthOpen = (mode) => {
    setAuthMode(mode);
    setAuthModalOpen(true);
  };
  
  const handleAuthClose = () => {
    setAuthModalOpen(false);
  };
  
  return (
    <>
      <AppBar position="sticky" color="default" elevation={2} sx={{ backgroundColor: 'white' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Logo */}
            <Typography
              variant="h6"
              component={RouterLink}
              to="/"
              sx={{
                mr: 2,
                display: 'flex',
                fontWeight: 700,
                color: 'primary.main',
                textDecoration: 'none',
              }}
            >
              <img 
                src={require('../images/tuttogym.jpeg')}
                alt="TuttoGym" 
                style={{ height: 150, marginRight: 1 }} 
              />
            </Typography>

            {isMobile ? (
              <>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Box sx={{ flexGrow: 1, display: 'flex', ml: 4 }}>
                  {[
                    { name: t('header.searchGyms'), path: '/search-gyms' },
                    { name: t('header.business'), path: '/business' },
                    { name: t('header.individual'), path: '/individual' },
                    { name: t('header.partners'), path: '/partners' },
                    { name: t('header.plansAndPrices'), path: '/plans-and-prices' },
                  ].map((item) => (
                    <Button
                      key={item.name}
                      component={RouterLink}
                      to={item.path}
                      sx={{ 
                        color: 'text.primary', 
                        mx: 1,
                        '&:hover': {
                          color: 'primary.main',
                          backgroundColor: 'transparent',
                        }
                      }}
                    >
                      {item.name}
                    </Button>
                  ))}
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LanguageSwitcher />
                  <CitySelector />
                  <Button 
                    color="primary" 
                    variant="outlined" 
                    sx={{ ml: 2 }}
                    onClick={() => handleAuthOpen('login')}
                  >
                    {t('header.login')}
                  </Button>
                  <Button 
                    color="primary" 
                    variant="contained" 
                    sx={{ ml: 2 }}
                    onClick={() => handleAuthOpen('register')}
                  >
                    {t('header.register')}
                  </Button>
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" sx={{ my: 2 }}>
            TuttoGym
          </Typography>
          <List>
            {[
              { name: t('header.searchGyms'), path: '/search-gyms' },
              { name: t('header.business'), path: '/business' },
              { name: t('header.individual'), path: '/individual' },
              { name: t('header.partners'), path: '/partners' },
              { name: t('header.plansAndPrices'), path: '/plans-and-prices' },
            ].map((item) => (
              <ListItem 
                key={item.name} 
                component={RouterLink} 
                to={item.path}
                sx={{ 
                  textAlign: 'center',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.08)',
                  }
                }}
              >
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <LanguageSwitcher />
          <CitySelector />
          <Button 
            color="primary" 
            variant="outlined" 
            fullWidth
            onClick={() => handleAuthOpen('login')}
          >
            {t('header.login')}
          </Button>
          <Button 
            color="primary" 
            variant="contained" 
            fullWidth
            onClick={() => handleAuthOpen('register')}
          >
            {t('header.register')}
          </Button>
        </Box>
      </Drawer>
      
      <AuthModal 
        open={authModalOpen} 
        onClose={handleAuthClose} 
        mode={authMode} 
      />
    </>
  );
};

export default Header; 