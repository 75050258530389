import axios from 'axios';

// Determine the base URL based on environment
const getBaseUrl = () => {
  // Check if running in browser or server
  if (typeof window !== 'undefined') {
    // In production (Vercel), use the production API URL
    if (window.location.hostname !== 'localhost' && 
        !window.location.hostname.includes('127.0.0.1')) {
      return 'https://api.tuttogym.com/api';
    }
  }
  // For local development, use localhost with port 3001
  return 'http://localhost:3001/api';
};

// Base API URL 
const apiClient = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  }
});

// Check-in related API calls
export const processCheckIn = async (checkInData) => {
  try {
    const response = await apiClient.post('/check-ins', checkInData);
    
    // Trigger dashboard refresh if available
    if (typeof window.dashboardRefresh === 'function') {
      window.dashboardRefresh();
    }
    
    // Dispatch custom event for real-time updates
    const event = new CustomEvent('newCheckIn', { detail: checkInData });
    window.dispatchEvent(event);
    
    return response.data;
  } catch (error) {
    console.error('Error processing check-in:', error);
    return {
      success: false,
      message: 'Error processing check-in: ' + (error.response?.data?.message || error.message)
    };
  }
};

export const getCheckInsByUserEmail = async (userEmail) => {
  try {
    const response = await apiClient.get(`/check-ins?email=${encodeURIComponent(userEmail)}`);
    return response.data;
  } catch (error) {
    console.error('Error getting check-ins by user email:', error);
    return [];
  }
};

// Authentication related API calls
export const login = async (email, password) => {
  try {
    const response = await apiClient.post('/auth/login', { email, password });
    
    const data = response.data;
    
    // Store authentication data in localStorage
    if (data.success && data.token) {
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('userRole', 'partner');
      localStorage.setItem('userEmail', data.user.email);
      localStorage.setItem('userName', data.user.name || '');
      localStorage.setItem('userId', data.user.id.toString());
      localStorage.setItem('userLocation', data.user.location || '');
      localStorage.setItem('allowNotifications', 'true');
    }
    
    return data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('userRole');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('userName');
  localStorage.removeItem('userId');
  localStorage.removeItem('userLocation');
  localStorage.removeItem('allowNotifications');
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('authToken');
};

export const getCurrentUser = () => {
  if (!isAuthenticated()) {
    return null;
  }
  
  return {
    id: localStorage.getItem('userId'),
    email: localStorage.getItem('userEmail'),
    name: localStorage.getItem('userName'),
    role: localStorage.getItem('userRole'),
    location: localStorage.getItem('userLocation')
  };
};

// Interested company related API calls
export const saveInterestedCompany = async (companyData) => {
  try {
    const response = await apiClient.post('/interested-company', companyData);
    return response.data;
  } catch (error) {
    console.error('Error saving interested company data:', error);
    return {
      success: false,
      message: 'Error saving company data: ' + (error.response?.data?.message || error.message)
    };
  }
};

// Partner related API calls
export const saveInterestedPartner = async (partnerData) => {
  try {
    const response = await apiClient.post('/interested-partner', partnerData);
    return response.data;
  } catch (error) {
    console.error('Error saving partner data:', error);
    return {
      success: false,
      message: 'Error saving partner data: ' + (error.response?.data?.message || error.message)
    };
  }
};

// Individual user signup related API calls
export const saveUserSignup = async (userData) => {
  try {
    const response = await apiClient.post('/user-signup', userData);
    return response.data;
  } catch (error) {
    console.error('Error saving user signup data:', error);
    return {
      success: false,
      message: 'Error saving user data: ' + (error.response?.data?.message || error.message)
    };
  }
};

// Fitness centers related API calls
export const getActivityTypes = async () => {
  try {
    const response = await apiClient.get('/fitness-centers/activity-types');
    return response.data;
  } catch (error) {
    console.error('Error getting activity types:', error);
    return {
      success: false,
      data: [],
      message: 'Error fetching activity types: ' + (error.response?.data?.message || error.message)
    };
  }
};

export const searchFitnessCenters = async (params) => {
  try {
    // Build query string from params
    const queryParams = new URLSearchParams();
    
    if (params.city) {
      queryParams.append('city', params.city);
    }
    
    if (params.address) {
      queryParams.append('address', params.address);
    }
    
    if (params.postalCode) {
      queryParams.append('postalCode', params.postalCode);
    }
    
    // Handle activities as comma-separated string if it's an array
    if (params.activityType && Array.isArray(params.activityType) && params.activityType.length > 0) {
      queryParams.append('activityType', params.activityType.join(','));
    } else if (params.activityType) {
      queryParams.append('activityType', params.activityType);
    }
    
    if (params.page) {
      queryParams.append('page', params.page.toString());
    }
    
    if (params.pageSize) {
      queryParams.append('pageSize', params.pageSize.toString());
    }
    
    const response = await apiClient.get(`/fitness-centers/search?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error searching fitness centers:', error);
    return {
      success: false,
      data: [],
      pagination: { total_items: 0, total_pages: 0, current_page: 1, page_size: 9 },
      message: 'Error searching fitness centers: ' + (error.response?.data?.message || error.message)
    };
  }
};

export const getCities = async () => {
  try {
    const response = await apiClient.get('/fitness-centers/cities');
    return {
      success: true,
      data: response.data.data || []
    };
  } catch (error) {
    console.error('Error getting cities:', error);
    return {
      success: false,
      data: [],
      message: 'Error fetching cities: ' + (error.response?.data?.message || error.message)
    };
  }
};

export const getPostalCodes = async () => {
  try {
    const response = await apiClient.get('/fitness-centers/postal-codes');
    return {
      success: true,
      data: response.data.data || []
    };
  } catch (error) {
    console.error('Error getting postal codes:', error);
    return {
      success: false,
      data: [],
      message: 'Error fetching postal codes: ' + (error.response?.data?.message || error.message)
    };
  }
}; 