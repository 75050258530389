import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const { t, i18n } = useTranslation();
  
  // Use translated FAQ questions from i18n
  const faqs = i18n.language === 'en' 
    ? t('faq.questions', { returnObjects: true }) 
    : [
        {
          question: '¿Cómo funciona TuttoGym?',
          answer: 'TuttoGym es una plataforma que te permite acceder a múltiples gimnasios y actividades con una sola membresía. Simplemente elige tu plan, regístrate y comienza a disfrutar de todos los gimnasios y actividades incluidas en tu membresía.'
        },
        {
          question: '¿Puedo cancelar mi suscripción en cualquier momento?',
          answer: 'Sí, puedes cancelar tu suscripción en cualquier momento. No hay contratos de permanencia. La cancelación será efectiva al final del período de facturación actual.'
        },
        {
          question: '¿Hay alguna tarifa de inscripción?',
          answer: 'No, no cobramos ninguna tarifa de inscripción. Solo pagas la cuota mensual del plan que elijas.'
        },
        {
          question: '¿Cuántas veces puedo visitar un mismo gimnasio?',
          answer: 'Puedes visitar cualquier gimnasio de la red tantas veces como quieras, sin límites, siempre dentro de los términos de tu plan.'
        },
        {
          question: '¿Cómo reservo una actividad?',
          answer: 'Puedes reservar actividades directamente desde nuestra aplicación móvil o sitio web. Selecciona la actividad, el horario y el gimnasio, y confirma tu reserva con unos pocos clics.'
        },
        {
          question: '¿Qué pasa si no puedo asistir a una actividad reservada?',
          answer: 'Te pedimos que canceles tu reserva con al menos 2 horas de antelación para liberar el espacio para otros usuarios. Las cancelaciones frecuentes sin previo aviso pueden resultar en restricciones temporales para hacer reservas.'
        }
      ];
  
  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Box className="section-title">
          <Typography variant="h2" component="h2">
            {t('faq.title')}
          </Typography>
          <Typography variant="body1">
            {t('faq.subtitle')}
          </Typography>
        </Box>
        
        <Box sx={{ mt: 4 }}>
          {faqs.map((faq, index) => (
            <Accordion 
              key={index}
              sx={{ 
                mb: 2,
                boxShadow: 'none',
                '&:before': { display: 'none' },
                borderRadius: '8px !important',
                overflow: 'hidden',
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ 
                  backgroundColor: 'background.paper',
                  '&:hover': { backgroundColor: 'action.hover' }
                }}
              >
                <Typography variant="h6" fontWeight={600}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: 'background.paper' }}>
                <Typography variant="body1">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;
