import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        translation: {
          // Contenido en español
          "common": {
            "cancel": "Cancelar",
            "next": "Siguiente",
            "back": "Atrás",
            "submit": "Enviar",
            "close": "Cerrar",
            "notSpecified": "No especificado",
            "loading": "Cargando..."
          },
          "header": {
            "searchGyms": "Buscar gimnasios",
            "business": "Empresas",
            "individual": "Particulares",
            "partners": "Partners",
            "plansAndPrices": "Planes y Precios",
            "register": "Registrarse",
            "login": "Iniciar Sesión",
            "cities": {
              "title": "Ciudad",
              "barcelona": "Barcelona",
              "santiago": "Santiago de Chile"
            }
          },
          "auth": {
            "client": "Cliente",
            "business": "Empresa",
            "partner": "Partner"
          },
          "hero": {
            "title": "TuttoGym",
            "subtitle": "Todas tus actividades físicas en un solo lugar"
          },
          "benefits": {
            "allGyms": "Todos los gimnasios",
            "allActivities": "Todas las actividades",
            "registeredBusinesses": "Empresas inscritas",
            "title": "Beneficios de TuttoGym",
            "subtitle": "Todo lo que necesitas para mantenerte activo y saludable en un solo lugar",
            "descriptions": {
              "gyms": "Acceso a más de 1,000 gimnasios con una sola membresía.",
              "activities": "Disfruta de una amplia variedad de actividades: yoga, natación, pádel, crossfit y más.",
              "businesses": "Las empresas nos confían para ofrecer beneficios de bienestar a sus empleados."
            }
          },
          "search": {
            "title": "Encuentra tu gimnasio ideal",
            "activities": "Actividades",
            "plans": "Planes",
            "location": "Ciudad o código postal",
            "search": "Buscar",
            "searching": "Buscando...",
            "noResults": "No se encontraron gimnasios que coincidan con tu búsqueda."
          },
          "searchGyms": {
            "helmet": "Buscar gimnasios | TuttoGym",
            "title": "Encuentra tu gimnasio ideal",
            "subtitle": "Filtra por ciudad, actividades o código postal",
            "cityFilter": "Ciudad",
            "selectCity": "Selecciona una ciudad",
            "activitiesFilter": "Actividades",
            "postalCodeFilter": "Código postal",
            "enterPostalCode": "Introduce un código postal",
            "searchButton": "Buscar",
            "noResults": "No se encontraron gimnasios que coincidan con tu búsqueda",
            "tryDifferentSearch": "Intenta con otros filtros o una búsqueda diferente"
          },
          "activities": {
            "all": "Todas",
            "padel": "Pádel",
            "yoga": "Yoga",
            "swimming": "Natación",
            "crossfit": "Crossfit",
            "fitness": "Fitness",
            "pilates": "Pilates",
            "boxing": "Boxeo",
            "cycling": "Ciclismo",
            "spinning": "Spinning",
            "zumba": "Zumba",
            "gimnasio": "Gimnasio",
            "aquagym": "Aquagym",
            "entrenamiento_personal": "Entrenamiento Personal",
            "polideportivo": "Polideportivo",
            "artes_marciales": "Artes Marciales",
            "escalada": "Escalada"
          },
          "plans": {
            "bronze": "Bronze",
            "silver": "Silver",
            "gold": "Gold",
            "platinum": "Platinum",
            "diamond": "Diamond",
            "title": "Explora Nuestros Planes",
            "subtitle": "Elige el plan que mejor se adapte a tus necesidades y objetivos",
            "features": {
              "gyms": "Acceso a gimnasios",
              "activities": "Actividades incluidas",
              "schedule": "Horario",
              "guests": "Invitados gratis",
              "trainer": "Entrenador personal",
              "complete": "completo",
              "all": "todos",
              "unlimited": "ilimitados"
            },
            "monthly": "/mes",
            "choosePlan": "Elegir Plan",
            "mostPopular": "Más popular"
          },
          "footer": {
            "apps": "Aplicaciones",
            "social": "Redes Sociales",
            "privacy": "Privacidad",
            "cookies": "Cookies",
            "terms": "Términos",
            "security": "Seguridad"
          },
          "faq": {
            "title": "Preguntas Frecuentes"
          },
          "pages": {
            "business": {
              "benefits": [
                {
                  "title": "Mejora la salud y bienestar de tus empleados",
                  "description": "Ofrece acceso a más de 1,000 gimnasios y actividades para promover un estilo de vida saludable."
                },
                {
                  "title": "Reduce el absentismo laboral",
                  "description": "Los empleados activos tienen un 27% menos de días de baja por enfermedad."
                },
                {
                  "title": "Aumenta la productividad",
                  "description": "El ejercicio regular mejora la concentración y el rendimiento en el trabajo."
                },
                {
                  "title": "Atrae y retiene talento",
                  "description": "El 87% de los empleados considera los beneficios de bienestar al elegir un empleador."
                }
              ],
              "features": [
                "Portal de administración personalizado",
                "Informes de uso y participación",
                "Soporte dedicado para empresas",
                "Facturación centralizada",
                "Personalización de planes según necesidades",
                "Integración con sistemas de RRHH",
                "Comunicación interna de beneficios",
                "Eventos corporativos exclusivos"
              ],
              "cta": {
                "title": "¿Listo para mejorar el bienestar de tu equipo?",
                "subtitle": "Únete a más de 500 empresas que ya confían en TuttoGym para sus programas de bienestar corporativo.",
                "button": "Contactar con un asesor"
              },
              "requestForm": {
                "title": "Solicitar Información",
                "advisorTitle": "Contactar un Asesor",
                "steps": ["Email", "Empresa", "Presupuesto", "Información Adicional", "Confirmación"],
                "emailStep": {
                  "title": "Introduce tu email",
                  "emailLabel": "Correo electrónico",
                  "emailRequired": "El email es obligatorio",
                  "emailInvalid": "Formato de email inválido"
                },
                "companyStep": {
                  "title": "Información de la empresa",
                  "companyNameLabel": "Nombre de la empresa",
                  "companyNameRequired": "El nombre de la empresa es obligatorio",
                  "employeeSizeLabel": "Número de empleados",
                  "employeeSizeRequired": "Por favor seleccione el tamaño de la empresa",
                  "phoneLabel": "Teléfono (opcional)"
                },
                "quoteStep": {
                  "title": "Calculando tu presupuesto...",
                  "calculating": "Por favor espera mientras calculamos un presupuesto personalizado para tu empresa...",
                  "quoteTitle": "Tu Presupuesto Estimado",
                  "quoteDescription": "Este es un precio anual estimado basado en el tamaño de tu empresa.",
                  "downloadButton": "Descargar Presupuesto",
                  "downloadSuccess": "¡Presupuesto descargado!"
                },
                "additionalInfoStep": {
                  "title": "Información Adicional",
                  "subtitle": "¿Tienes alguna pregunta o requisito específico?",
                  "messageLabel": "Tu mensaje (opcional)"
                },
                "confirmationStep": {
                  "title": "¡Gracias!",
                  "infoMessage": "Hemos recibido tu solicitud de información. Nuestro equipo se pondrá en contacto contigo en breve con más detalles.",
                  "advisorMessage": "Alguien de ventas@tuttogym.com se pondrá en contacto contigo pronto."
                },
                "buttons": {
                  "back": "Atrás",
                  "next": "Siguiente",
                  "submit": "Enviar",
                  "close": "Cerrar"
                }
              }
            },
            "individual": {
              "hero": {
                "title": "Your fitness journey starts here",
                "subtitle": "Access more than 1,000 gyms and activities with a single membership",
                "button": "Start now"
              },
              "benefits": [
                {
                  "title": "Freedom to choose",
                  "description": "Train wherever and whenever you want with access to more than 1,000 gyms."
                },
                {
                  "title": "Variety of activities",
                  "description": "From yoga to CrossFit, swimming to boxing - find what motivates you."
                },
                {
                  "title": "No commitments",
                  "description": "No long-term contracts. Cancel your membership whenever you want."
                },
                {
                  "title": "Affordable prices",
                  "description": "Plans starting at €29.99/month, much less than a traditional gym membership."
                }
              ],
              "features": {
                "title": "Why choose TuttoGym?",
                "subtitle": "The smart way to stay active",
                "items": [
                  "Access to more than 1,000 gyms",
                  "Book classes instantly",
                  "No registration fees",
                  "Bring friends with you",
                  "Personalized recommendations",
                  "24/7 customer support"
                ]
              },
              "testimonials": {
                "title": "What our members say",
                "items": [
                  {
                    "name": "Sarah Johnson",
                    "text": "TuttoGym has completely changed how I exercise. I love being able to try different activities and gyms depending on my mood and schedule."
                  },
                  {
                    "name": "Michael Rodriguez",
                    "text": "The flexibility is amazing. I travel a lot for work and can always find a gym nearby. It's like having a membership to every gym in the city!"
                  },
                  {
                    "name": "Emma Thompson",
                    "text": "I've discovered activities I never would have tried before. The variety keeps me motivated and I've never been in better shape."
                  }
                ]
              }
            },
            "partners": {
              "hero": {
                "title": "Grow your business with TuttoGym",
                "subtitle": "Join our network of gyms and fitness centers and reach thousands of new customers",
                "button": "Become a partner"
              },
              "benefits": [
                {
                  "title": "Increase your revenue",
                  "description": "Attract new customers and fill unused capacity without cannibalizing your existing membership base."
                },
                {
                  "title": "Zero risk",
                  "description": "No upfront costs or monthly fees. You only share revenue from the customers we bring to you."
                },
                {
                  "title": "Simple integration",
                  "description": "Our system integrates seamlessly with your existing management software and processes."
                },
                {
                  "title": "Full control",
                  "description": "You decide when TuttoGym members can visit your facility, maintaining control of your capacity."
                }
              ],
              "howItWorks": {
                "title": "How it works",
                "steps": [
                  {
                    "title": "Join our network",
                    "description": "Sign up as a TuttoGym partner and set up your profile with photos, amenities, and available activities."
                  },
                  {
                    "title": "Set your availability",
                    "description": "Define when TuttoGym members can visit your facility based on your capacity and peak hours."
                  },
                  {
                    "title": "Welcome new customers",
                    "description": "TuttoGym members check in using our app, and you verify their identity with a simple QR code scan."
                  },
                  {
                    "title": "Get paid",
                    "description": "Receive monthly payments for all TuttoGym visits to your facility, with detailed reporting and analytics."
                  }
                ]
              },
              "cta": {
                "title": "Ready to expand your customer base?",
                "subtitle": "Join over 1,000 fitness facilities already partnering with TuttoGym",
                "button": "Apply now"
              },
              "requestForm": {
                "title": "Request Information",
                "advisorTitle": "Contact an Advisor",
                "steps": ["Email", "Company", "Quote", "Additional Info", "Confirmation"],
                "emailStep": {
                  "title": "Enter your email",
                  "emailLabel": "Email",
                  "emailRequired": "Email is required",
                  "emailInvalid": "Invalid email format"
                },
                "companyStep": {
                  "title": "Company Information",
                  "companyNameLabel": "Gym Name",
                  "companyNameRequired": "Gym name is required",
                  "clientSizeLabel": "Number of Monthly Clients",
                  "clientSizeRequired": "Please select number of clients",
                  "avgPriceLabel": "Monthly Average Fee per Client",
                  "avgPriceRequired": "Please enter a valid average fee",
                  "phoneLabel": "Phone (optional)"
                },
                "quoteStep": {
                  "title": "Calculating your quote...",
                  "calculating": "Please wait while we calculate a personalized quote for your gym...",
                  "quoteTitle": "Your Estimated Quote",
                  "quoteDescription": "This is an estimated annual price based on your gym size.",
                  "downloadButton": "Download Quote",
                  "downloadSuccess": "Quote downloaded!"
                },
                "additionalInfoStep": {
                  "title": "Additional Information",
                  "subtitle": "Do you have any specific questions or requirements?",
                  "messageLabel": "Your message (optional)"
                },
                "confirmationStep": {
                  "title": "Thank You!",
                  "infoMessage": "We have received your partner request. Our team will contact you shortly with more details.",
                  "advisorMessage": "Someone from partners@tuttogym.com will contact you soon."
                },
                "buttons": {
                  "back": "Back",
                  "next": "Next",
                  "submit": "Submit",
                  "close": "Close"
                }
              }
            },
            "plansAndPrices": {
              "title": "Planes y Precios",
              "subtitle": "Elige el plan que mejor se adapte a tus necesidades",
              "metaDescription": "Descubre nuestros planes y precios para particulares y empresas. Accede a miles de gimnasios y actividades con una sola membresía.",
              "tabs": {
                "individual": "Individual",
                "business": "Empresa"
              },
              "billing": {
                "monthly": "Mensual",
                "annual": "Anual",
                "saveText": "Ahorra 20%"
              },
              "features": {
                "access100": "Acceso a 100+ gimnasios",
                "access500": "Acceso a 500+ gimnasios",
                "access800": "Acceso a 800+ gimnasios",
                "access1000": "Acceso a 1000+ gimnasios",
                "activities3": "3 actividades incluidas",
                "activities5": "5 actividades incluidas",
                "activitiesAll": "Todas las actividades incluidas",
                "limitedHours": "Horas limitadas (8:00-16:00)",
                "fullHours": "Horas completas",
                "poolAccess": "Acceso a la piscina",
                "freeGuests": "Invitados gratis",
                "freeGuests1": "1 invitado gratis al mes",
                "freeGuests2": "2 invitados gratis al mes",
                "personalTrainer": "Entrenador personal",
                "personalTrainer1": "1 sesión con entrenador personal",
                "personalTrainer2": "2 sesiones con entrenador personal"
              },
              "business": {
                "perUser": "/ usuario / mes",
                "forUsers": "Para {min}-{max} usuarios",
                "forUsersUnlimited": "Para {min}-∞ usuarios",
                "customPrice": "Personalizado",
                "features": {
                  "access500": "Acceso a 500+ gimnasios",
                  "access800": "Acceso a 800+ gimnasios",
                  "access1000": "Acceso a 1000+ gimnasios",
                  "activities5": "5 actividades incluidas",
                  "activitiesAll": "Todas las actividades incluidas",
                  "adminPanel": "Panel de administración",
                  "advancedAdminPanel": "Panel de administración avanzada",
                  "customAdminPanel": "Panel de administración personalizado",
                  "basicReports": "Reportes básicos",
                  "detailedReports": "Reportes detallados",
                  "customReports": "Reportes personalizados",
                  "emailSupport": "Soporte de correo electrónico",
                  "prioritySupport": "Soporte prioritario",
                  "dedicatedManager": "Gestor de cuenta dedicado",
                  "corporateEvents": "Eventos corporativos",
                  "corporateEvents2": "2 eventos corporativos al año",
                  "corporateEventsUnlimited": "Eventos corporativos ilimitados"
                }
              },
              "buttons": {
                "choosePlan": "Elegir Plan",
                "contact": "Contactar"
              },
              "customPlan": {
                "title": "¿Necesita un plan personalizado?",
                "subtitle": "Si tiene necesidades específicas o está buscando una solución adaptada, póngase en contacto con nuestro equipo.",
                "button": "Contactar ventas"
              },
              "companyPayment": {
                "title": "¿Quieres que tu empresa te pague el 50% o el 100% de tu mensualidad?",
                "button": "Haz click aquí"
              },
              "faq": {
                "title": "Preguntas frecuentes sobre planes y precios",
                "questions": [
                  {
                    "question": "¿Puedo cambiar de plan en cualquier momento?",
                    "answer": "Sí, puedes actualizar o cambiar tu plan en cualquier momento. Los cambios se aplicarán en tu próximo ciclo de facturación."
                  },
                  {
                    "question": "¿Hay algún compromiso de permanencia?",
                    "answer": "No, no hay compromiso de permanencia. Puedes cancelar tu suscripción en cualquier momento."
                  },
                  {
                    "question": "¿Qué métodos de pago aceptáis?",
                    "answer": "Aceptamos tarjetas de crédito/débito (Visa, Mastercard, American Express) y PayPal. Para planes empresariales, también ofrecemos transferencia bancaria."
                  },
                  {
                    "question": "¿Ofrecéis descuentos para estudiantes?",
                    "answer": "Sí, ofrecemos un 15% de descuento para estudiantes con identificación válida. Contacta con nuestro servicio de atención al cliente para más información."
                  }
                ]
              },
              "dialog": {
                "title": {
                  "step1": "Solicitar patrocinio de empresa",
                  "step2": "Información de contacto"
                },
                "steps": {
                  "company": "Información de empresa",
                  "contact": "Datos de contacto"
                },
                "labels": {
                  "company": "Nombre de la empresa",
                  "email": "Email de contacto de la empresa"
                },
                "success": "¡Tu solicitud ha sido enviada! Por favor, envía un correo a ventas@tuttogym.com con la información de la empresa."
              }
            }
          }
        }
      },
      en: {
        translation: {
          // Contenido en inglés
          "common": {
            "cancel": "Cancel",
            "next": "Next",
            "back": "Back",
            "submit": "Submit",
            "close": "Close",
            "notSpecified": "Not specified",
            "loading": "Loading..."
          },
          "header": {
            "searchGyms": "Find Gyms",
            "business": "Business",
            "individual": "Individual",
            "partners": "Partners",
            "plansAndPrices": "Plans & Prices",
            "register": "Register",
            "login": "Login",
            "cities": {
              "title": "City",
              "barcelona": "Barcelona",
              "santiago": "Santiago de Chile"
            }
          },
          "auth": {
            "client": "Client",
            "business": "Business",
            "partner": "Partner"
          },
          "hero": {
            "title": "TuttoGym",
            "subtitle": "All your physical activities in one place"
          },
          "benefits": {
            "allGyms": "All Gyms",
            "allActivities": "All Activities",
            "registeredBusinesses": "Registered Businesses",
            "title": "Benefits of TuttoGym",
            "subtitle": "Everything you need to stay active and healthy in one place",
            "descriptions": {
              "gyms": "Access more than 1,000 gyms with a single membership.",
              "activities": "Enjoy a wide variety of activities: yoga, swimming, padel, crossfit and more.",
              "businesses": "Companies trust us to offer wellness benefits to their employees."
            }
          },
          "search": {
            "title": "Find your ideal gym",
            "activities": "Activities",
            "plans": "Plans",
            "location": "City or postal code",
            "search": "Search",
            "searching": "Searching...",
            "noResults": "No gyms found matching your search."
          },
          "searchGyms": {
            "helmet": "Search Gyms | TuttoGym",
            "title": "Find Your Ideal Gym",
            "subtitle": "Filter by city, activities or postal code",
            "cityFilter": "City",
            "selectCity": "Select a city",
            "activitiesFilter": "Activities",
            "postalCodeFilter": "Postal Code",
            "enterPostalCode": "Enter a postal code",
            "searchButton": "Search",
            "noResults": "No gyms found matching your search",
            "tryDifferentSearch": "Try different filters or a different search"
          },
          "activities": {
            "all": "All",
            "padel": "Padel",
            "yoga": "Yoga",
            "swimming": "Swimming",
            "crossfit": "Crossfit",
            "fitness": "Fitness",
            "pilates": "Pilates",
            "boxing": "Boxing",
            "cycling": "Cycling",
            "spinning": "Spinning",
            "zumba": "Zumba",
            "gimnasio": "Gym",
            "aquagym": "Aquagym",
            "entrenamiento_personal": "Personal Training",
            "polideportivo": "Sports Center",
            "artes_marciales": "Martial Arts",
            "escalada": "Climbing"
          },
          "plans": {
            "bronze": "Bronze",
            "silver": "Silver",
            "gold": "Gold",
            "platinum": "Platinum",
            "diamond": "Diamond",
            "title": "Explore Our Plans",
            "subtitle": "Choose the plan that best suits your needs and goals",
            "features": {
              "gyms": "Gym access",
              "activities": "Included activities",
              "schedule": "Schedule",
              "guests": "Free guests",
              "trainer": "Personal trainer",
              "complete": "complete",
              "all": "all",
              "unlimited": "unlimited"
            },
            "monthly": "/month",
            "choosePlan": "Choose Plan",
            "mostPopular": "Most popular"
          },
          "footer": {
            "apps": "Apps",
            "social": "Social Media",
            "privacy": "Privacy",
            "cookies": "Cookies",
            "terms": "Terms",
            "security": "Security"
          },
          "faq": {
            "title": "Frequently Asked Questions",
            "subtitle": "Answers to the most frequently asked questions about TuttoGym",
            "questions": [
              {
                "question": "How does TuttoGym work?",
                "answer": "TuttoGym is a platform that allows you to access multiple gyms and activities with a single membership. Simply choose your plan, register, and start enjoying all the gyms and activities included in your membership."
              },
              {
                "question": "Can I cancel my subscription at any time?",
                "answer": "Yes, you can cancel your subscription at any time. There are no permanence contracts. The cancellation will be effective at the end of the current billing period."
              },
              {
                "question": "Is there any registration fee?",
                "answer": "No, we don't charge any registration fee. You only pay the monthly fee for the plan you choose."
              },
              {
                "question": "How many times can I visit the same gym?",
                "answer": "You can visit any gym in the network as many times as you want, without limits, always within the terms of your plan."
              },
              {
                "question": "How do I book an activity?",
                "answer": "You can book activities directly from our mobile app or website. Select the activity, time, and gym, and confirm your reservation with just a few clicks."
              },
              {
                "question": "What happens if I can't attend a booked activity?",
                "answer": "We ask you to cancel your reservation at least 2 hours in advance to free up space for other users. Frequent cancellations without prior notice may result in temporary restrictions on making reservations."
              }
            ]
          },
          "pages": {
            "business": {
              "benefits": [
                {
                  "title": "Improve your employees' health and well-being",
                  "description": "Offer access to more than 1,000 gyms and activities to promote a healthy lifestyle."
                },
                {
                  "title": "Reduce workplace absenteeism",
                  "description": "Active employees have 27% fewer sick days."
                },
                {
                  "title": "Increase productivity",
                  "description": "Regular exercise improves concentration and performance at work."
                },
                {
                  "title": "Attract and retain talent",
                  "description": "87% of employees consider wellness benefits when choosing an employer."
                }
              ],
              "features": [
                "Customized administration portal",
                "Usage and participation reports",
                "Dedicated business support",
                "Centralized billing",
                "Customization of plans according to needs",
                "Integration with HR systems",
                "Internal benefits communication",
                "Exclusive corporate events"
              ],
              "cta": {
                "title": "Ready to improve your team's well-being?",
                "subtitle": "Join more than 500 companies that already trust TuttoGym for their corporate wellness programs.",
                "button": "Contact an advisor"
              },
              "requestForm": {
                "title": "Request Information",
                "advisorTitle": "Contact an Advisor",
                "steps": ["Email", "Company", "Quote", "Additional Info", "Confirmation"],
                "emailStep": {
                  "title": "Enter your email",
                  "emailLabel": "Email",
                  "emailRequired": "Email is required",
                  "emailInvalid": "Invalid email format"
                },
                "companyStep": {
                  "title": "Company Information",
                  "companyNameLabel": "Gym Name",
                  "companyNameRequired": "Gym name is required",
                  "clientSizeLabel": "Number of Monthly Clients",
                  "clientSizeRequired": "Please select number of clients",
                  "avgPriceLabel": "Monthly Average Fee per Client",
                  "avgPriceRequired": "Please enter a valid average fee",
                  "phoneLabel": "Phone (optional)"
                },
                "quoteStep": {
                  "title": "Calculating your quote...",
                  "calculating": "Please wait while we calculate a personalized quote for your company...",
                  "quoteTitle": "Your Estimated Quote",
                  "quoteDescription": "This is an estimated annual price based on your company size.",
                  "downloadButton": "Download Quote",
                  "downloadSuccess": "Quote downloaded!"
                },
                "additionalInfoStep": {
                  "title": "Additional Information",
                  "subtitle": "Do you have any specific questions or requirements?",
                  "messageLabel": "Your message (optional)"
                },
                "confirmationStep": {
                  "title": "Thank You!",
                  "infoMessage": "We have received your information request. Our team will contact you shortly with more details.",
                  "advisorMessage": "Someone from ventas@tuttogym.com will contact you soon."
                },
                "buttons": {
                  "back": "Back",
                  "next": "Next",
                  "submit": "Submit",
                  "close": "Close"
                }
              }
            },
            "individual": {
              "hero": {
                "title": "Your fitness journey starts here",
                "subtitle": "Access more than 1,000 gyms and activities with a single membership",
                "button": "Start now"
              },
              "benefits": [
                {
                  "title": "Freedom to choose",
                  "description": "Train wherever and whenever you want with access to more than 1,000 gyms."
                },
                {
                  "title": "Variety of activities",
                  "description": "From yoga to CrossFit, swimming to boxing - find what motivates you."
                },
                {
                  "title": "No commitments",
                  "description": "No long-term contracts. Cancel your membership whenever you want."
                },
                {
                  "title": "Affordable prices",
                  "description": "Plans starting at €29.99/month, much less than a traditional gym membership."
                }
              ],
              "features": {
                "title": "Why choose TuttoGym?",
                "subtitle": "The smart way to stay active",
                "items": [
                  "Access to more than 1,000 gyms",
                  "Book classes instantly",
                  "No registration fees",
                  "Bring friends with you",
                  "Personalized recommendations",
                  "24/7 customer support"
                ]
              },
              "testimonials": {
                "title": "What our members say",
                "items": [
                  {
                    "name": "Sarah Johnson",
                    "text": "TuttoGym has completely changed how I exercise. I love being able to try different activities and gyms depending on my mood and schedule."
                  },
                  {
                    "name": "Michael Rodriguez",
                    "text": "The flexibility is amazing. I travel a lot for work and can always find a gym nearby. It's like having a membership to every gym in the city!"
                  },
                  {
                    "name": "Emma Thompson",
                    "text": "I've discovered activities I never would have tried before. The variety keeps me motivated and I've never been in better shape."
                  }
                ]
              }
            },
            "partners": {
              "hero": {
                "title": "Grow your business with TuttoGym",
                "subtitle": "Join our network of gyms and fitness centers and reach thousands of new customers",
                "button": "Become a partner"
              },
              "benefits": [
                {
                  "title": "Increase your revenue",
                  "description": "Attract new customers and fill unused capacity without cannibalizing your existing membership base."
                },
                {
                  "title": "Zero risk",
                  "description": "No upfront costs or monthly fees. You only share revenue from the customers we bring to you."
                },
                {
                  "title": "Simple integration",
                  "description": "Our system integrates seamlessly with your existing management software and processes."
                },
                {
                  "title": "Full control",
                  "description": "You decide when TuttoGym members can visit your facility, maintaining control of your capacity."
                }
              ],
              "howItWorks": {
                "title": "How it works",
                "steps": [
                  {
                    "title": "Join our network",
                    "description": "Sign up as a TuttoGym partner and set up your profile with photos, amenities, and available activities."
                  },
                  {
                    "title": "Set your availability",
                    "description": "Define when TuttoGym members can visit your facility based on your capacity and peak hours."
                  },
                  {
                    "title": "Welcome new customers",
                    "description": "TuttoGym members check in using our app, and you verify their identity with a simple QR code scan."
                  },
                  {
                    "title": "Get paid",
                    "description": "Receive monthly payments for all TuttoGym visits to your facility, with detailed reporting and analytics."
                  }
                ]
              },
              "cta": {
                "title": "Ready to expand your customer base?",
                "subtitle": "Join over 1,000 fitness facilities already partnering with TuttoGym",
                "button": "Apply now"
              },
              "requestForm": {
                "title": "Request Information",
                "advisorTitle": "Contact an Advisor",
                "steps": ["Email", "Company", "Quote", "Additional Info", "Confirmation"],
                "emailStep": {
                  "title": "Enter your email",
                  "emailLabel": "Email",
                  "emailRequired": "Email is required",
                  "emailInvalid": "Invalid email format"
                },
                "companyStep": {
                  "title": "Company Information",
                  "companyNameLabel": "Gym Name",
                  "companyNameRequired": "Gym name is required",
                  "clientSizeLabel": "Number of Monthly Clients",
                  "clientSizeRequired": "Please select number of clients",
                  "avgPriceLabel": "Monthly Average Fee per Client",
                  "avgPriceRequired": "Please enter a valid average fee",
                  "phoneLabel": "Phone (optional)"
                },
                "quoteStep": {
                  "title": "Calculating your quote...",
                  "calculating": "Please wait while we calculate a personalized quote for your gym...",
                  "quoteTitle": "Your Estimated Quote",
                  "quoteDescription": "This is an estimated annual price based on your gym size.",
                  "downloadButton": "Download Quote",
                  "downloadSuccess": "Quote downloaded!"
                },
                "additionalInfoStep": {
                  "title": "Additional Information",
                  "subtitle": "Do you have any specific questions or requirements?",
                  "messageLabel": "Your message (optional)"
                },
                "confirmationStep": {
                  "title": "Thank You!",
                  "infoMessage": "We have received your partner request. Our team will contact you shortly with more details.",
                  "advisorMessage": "Someone from partners@tuttogym.com will contact you soon."
                },
                "buttons": {
                  "back": "Back",
                  "next": "Next",
                  "submit": "Submit",
                  "close": "Close"
                }
              }
            },
            "plansAndPrices": {
              "title": "Plans and Prices",
              "subtitle": "Choose the plan that best suits your needs",
              "metaDescription": "Discover our plans and prices for individuals and businesses. Access thousands of gyms and activities with a single membership.",
              "tabs": {
                "individual": "Individual",
                "business": "Business"
              },
              "billing": {
                "monthly": "Monthly",
                "annual": "Annual",
                "saveText": "Save 20%"
              },
              "features": {
                "access100": "Access to 100+ gyms",
                "access500": "Access to 500+ gyms",
                "access800": "Access to 800+ gyms",
                "access1000": "Access to 1000+ gyms",
                "activities3": "3 included activities",
                "activities5": "5 included activities",
                "activitiesAll": "All activities included",
                "limitedHours": "Limited hours (8:00-16:00)",
                "fullHours": "Full hours",
                "poolAccess": "Pool access",
                "freeGuests": "Free guests",
                "freeGuests1": "1 free guest per month",
                "freeGuests2": "2 free guests per month",
                "personalTrainer": "Personal trainer",
                "personalTrainer1": "1 session with personal trainer",
                "personalTrainer2": "2 sessions with personal trainer"
              },
              "business": {
                "perUser": "/ user / month",
                "forUsers": "For {min}-{max} users",
                "forUsersUnlimited": "For {min}-∞ users",
                "customPrice": "Custom",
                "features": {
                  "access500": "Access to 500+ gyms",
                  "access800": "Access to 800+ gyms",
                  "access1000": "Access to 1000+ gyms",
                  "activities5": "5 included activities",
                  "activitiesAll": "All activities included",
                  "adminPanel": "Admin panel",
                  "advancedAdminPanel": "Advanced admin panel",
                  "customAdminPanel": "Custom admin panel",
                  "basicReports": "Basic reports",
                  "detailedReports": "Detailed reports",
                  "customReports": "Custom reports",
                  "emailSupport": "Email support",
                  "prioritySupport": "Priority support",
                  "dedicatedManager": "Dedicated account manager",
                  "corporateEvents": "Corporate events",
                  "corporateEvents2": "2 corporate events per year",
                  "corporateEventsUnlimited": "Unlimited corporate events"
                }
              },
              "buttons": {
                "choosePlan": "Choose Plan",
                "contact": "Contact Us"
              },
              "customPlan": {
                "title": "Need a custom plan?",
                "subtitle": "If you have specific needs or are looking for a tailored solution, contact our team.",
                "button": "Contact sales"
              },
              "companyPayment": {
                "title": "Want your company to pay 50% or 100% of your membership?",
                "button": "Click here"
              },
              "faq": {
                "title": "Frequently asked questions about plans and prices",
                "questions": [
                  {
                    "question": "Can I change my plan at any time?",
                    "answer": "Yes, you can upgrade or change your plan at any time. Changes will apply in your next billing cycle."
                  },
                  {
                    "question": "Is there a commitment period?",
                    "answer": "No, there is no commitment period. You can cancel your subscription at any time."
                  },
                  {
                    "question": "What payment methods do you accept?",
                    "answer": "We accept credit/debit cards (Visa, Mastercard, American Express) and PayPal. For business plans, we also offer bank transfer."
                  },
                  {
                    "question": "Do you offer student discounts?",
                    "answer": "Yes, we offer a 15% discount for students with valid identification. Contact our customer service for more information."
                  }
                ]
              },
              "dialog": {
                "title": {
                  "step1": "Request company sponsorship",
                  "step2": "Contact information"
                },
                "steps": {
                  "company": "Company information",
                  "contact": "Contact details"
                },
                "labels": {
                  "company": "Company name",
                  "email": "Company contact email"
                },
                "success": "Your request has been sent! Please send an email to ventas@tuttogym.com with the company information."
              }
            }
          }
        }
      }
    }
  });

export default i18n; 