import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import tuttoGymLogo from '../../images/tuttogym.jpeg';
import { useNavigate } from 'react-router-dom';
import NotificationService from '../../components/NotificationService';
import { logout, getCurrentUser } from '../../services/api';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip,
  IconButton,
  Tabs,
  Tab,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  TextField,
  Menu,
  MenuItem,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Switch,
  FormControlLabel,
  AppBar,
  Toolbar,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Visibility as VisibilityIcon,
  Notifications as NotificationsIcon,
  ExitToApp as ExitToAppIcon,
  Dashboard as DashboardIcon,
  History as HistoryIcon,
  Person as PersonIcon,
  Refresh as RefreshIcon,
  Settings as SettingsIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  RemoveRedEye as EyeIcon,
  NotificationsActive as NotificationsActiveIcon,
  NotificationsOff as NotificationsOffIcon,
  VpnKey as VpnKeyIcon,
} from '@mui/icons-material';
import { format, isToday } from 'date-fns';
import { es } from 'date-fns/locale';

// Determine API URL based on environment
const getApiUrl = () => {
  // Check if running in production (Vercel)
  if (typeof window !== 'undefined' && 
      window.location.hostname !== 'localhost' && 
      !window.location.hostname.includes('127.0.0.1')) {
    return 'https://api.tuttogym.com/api/check-ins';
  }
  // For local development
  return 'http://localhost:3000/api/check-ins';
};

const API_URL = getApiUrl();

const Dashboard = () => {
  const navigate = useNavigate();
  const [checkins, setCheckins] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [currentUserName, setCurrentUserName] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [currentUserLocation, setCurrentUserLocation] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCheckin, setSelectedCheckin] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [checkinToReject, setCheckinToReject] = useState(null);
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordError, setPasswordError] = useState('');
  const [allowNotifications, setAllowNotifications] = useState(
    localStorage.getItem('allowNotifications') === 'true'
  );
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const previousPendingCount = useRef(0);

  // Stats
  const totalCheckins = checkins.length;
  const todayCheckins = checkins.filter(checkin => isToday(new Date(checkin.timestamp))).length;
  const pendingCheckins = checkins.filter(checkin => checkin.status === 'pending').length;
  
  // Calculate remaining time for pending check-ins
  const [currentTime, setCurrentTime] = useState(new Date());
  
  // Update current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    
    return () => clearInterval(timer);
  }, []);

  // Function to load check-ins from API
  const loadCheckIns = useCallback(async (silent = false) => {
    try {
      setLoading(true);
      
      // Get current user data
      const user = getCurrentUser();
      if (!user || !user.email) {
        console.error('No user data found');
        navigate('/panel');
        return;
      }
      
      setCurrentUserEmail(user.email);
      setCurrentUserName(user.name || '');
      setCurrentUserId(user.id || '');
      setCurrentUserLocation(user.location || '');
      
      // Fetch all check-ins from API
      const response = await fetch(`${API_URL}`);
      if (!response.ok) {
        throw new Error('Failed to fetch check-ins');
      }
      
      const data = await response.json();
      
      // Filter check-ins that belong to this user (gym/partner)
      const userCheckIns = data.filter(checkin => checkin.userEmail === user.email);
      
      // Sort by date (most recent first)
      const sortedCheckIns = userCheckIns.sort((a, b) => 
        new Date(b.timestamp) - new Date(a.timestamp)
      );
      
      setCheckins(sortedCheckIns);
      
      // Update pending notifications
      const pendingNotifications = sortedCheckIns.filter(checkin => checkin.status === 'pending');
      setNotifications(pendingNotifications);
      
      // Check if there are new pending check-ins
      if (pendingNotifications.length > previousPendingCount.current && !silent) {
        // Show browser notification if allowed
        if (allowNotifications) {
          // Find the newest pending check-in
          const newestPending = pendingNotifications.sort((a, b) => 
            new Date(b.timestamp) - new Date(a.timestamp)
          )[0];
          
          if (newestPending) {
            NotificationService.showCheckInNotification(
              newestPending,
              // View handler
              (checkin) => {
                handleViewCheckin(checkin);
              },
              // Approve handler
              (checkinId) => {
                handleApproveCheckin(checkinId);
              }
            );
          }
        }
        
        // Show snackbar notification
        setSnackbarMessage(`Tienes ${pendingNotifications.length} check-ins pendientes`);
        setSnackbarOpen(true);
      }
      
      // Update previous count
      previousPendingCount.current = pendingNotifications.length;
      
      console.log(`Dashboard updated for ${user.email}: ${sortedCheckIns.length} check-ins loaded`);
    } catch (error) {
      console.error('Error loading check-ins:', error);
    } finally {
      setLoading(false);
    }
  }, [navigate, allowNotifications]);

  // Initial load effect
  useEffect(() => {
    // Initial load
    loadCheckIns();
  }, [loadCheckIns]);
  
  // Set up notification permission check
  useEffect(() => {
    if (allowNotifications && 'Notification' in window && Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, [allowNotifications]);

  // Initial authentication check
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/panel');
    }
  }, [navigate]);

  // Handle check-in actions
  const handleApproveCheckin = async (checkinId) => {
    try {
      // Optimistic update
      const updatedCheckins = checkins.map(checkin => 
        checkin.id === checkinId 
          ? { ...checkin, status: 'approved' } 
          : checkin
      );
      setCheckins(updatedCheckins);
      
      // Update pending notifications
      const pendingNotifications = updatedCheckins.filter(checkin => checkin.status === 'pending');
      setNotifications(pendingNotifications);
      
      // Send actual request to server
      const response = await fetch(`${API_URL}/${checkinId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'approved',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to approve check-in');
        // If error, we could revert the optimistic update here
      }
    } catch (error) {
      console.error('Error approving check-in:', error);
      // Reload to get the correct state
      loadCheckIns();
    }
  };

  const handleRejectCheckin = async () => {
    if (!checkinToReject) return;

    try {
      // Optimistic update
      const updatedCheckins = checkins.map(checkin => 
        checkin.id === checkinToReject.id 
          ? { ...checkin, status: 'rejected', comments: rejectReason || 'Rejected by admin' } 
          : checkin
      );
      setCheckins(updatedCheckins);
      
      // Update pending notifications
      const pendingNotifications = updatedCheckins.filter(checkin => checkin.status === 'pending');
      setNotifications(pendingNotifications);
      
      // Close dialog and reset state
      setRejectDialogOpen(false);
      setCheckinToReject(null);
      setRejectReason('');

      // Send actual request to server
      const response = await fetch(`${API_URL}/${checkinToReject.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'rejected',
          comments: rejectReason || 'Rejected by admin',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to reject check-in');
        // If error, we could revert the optimistic update here
      }
    } catch (error) {
      console.error('Error rejecting check-in:', error);
      // Reload to get the correct state
      loadCheckIns();
    }
  };

  const handleViewCheckin = (checkin) => {
    setSelectedCheckin(checkin);
    setViewDialogOpen(true);
  };

  const handleOpenRejectDialog = (checkin) => {
    setCheckinToReject(checkin);
    setRejectDialogOpen(true);
  };

  const handleChangePassword = () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPasswordError('Las contraseñas no coinciden');
      return;
    }

    setPasswordError('');
    setChangePasswordDialogOpen(false);
    setPasswordData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    alert('¡Contraseña cambiada exitosamente!');
  };
  
  const handleLogout = () => {
    logout();
    navigate('/panel');
  };
  
  const handleToggleNotifications = (event) => {
    const newValue = event.target.checked;
    setAllowNotifications(newValue);
    localStorage.setItem('allowNotifications', newValue.toString());
    
    // Request notification permission if enabling notifications
    if (newValue) {
      NotificationService.init();
    }
  };
  
  const handleRefresh = () => {
    loadCheckIns();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* App Bar with title and user info */}
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              component="img"
              src={tuttoGymLogo}
              alt="TuttoGym Logo"
              sx={{ height: 40, mr: 2 }}
            />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {currentUserName || 'Panel de Administración'}
            </Typography>
          </Box>
          
          <Box sx={{ flexGrow: 1 }} />
          
          {currentUserEmail && (
            <Typography variant="body2" sx={{ mr: 2 }}>
              {currentUserEmail}
            </Typography>
          )}
          

          
          <IconButton 
            color="inherit" 
            onClick={(event) => setSettingsMenuAnchor(event.currentTarget)}
            size="large"
          >
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
      {/* Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Check-ins
              </Typography>
              <Typography variant="h3">
                {totalCheckins}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Check-ins Hoy
              </Typography>
              <Typography variant="h3">
                {todayCheckins}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Pendientes
              </Typography>
              <Typography variant="h3" color="warning.main">
                {pendingCheckins}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Check-ins Table */}
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', mb: 2 }}>
      </Paper>

      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
            <Tab 
              label={
                <Badge badgeContent={notifications.length} color="error">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <NotificationsIcon sx={{ mr: 1 }} />
                    Pending
                  </Box>
                </Badge>
              } 
            />
            <Tab 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <HistoryIcon sx={{ mr: 1 }} />
                  All Check-ins
                </Box>
              }
            />
          </Tabs>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Usuario</TableCell>
                <TableCell>Fecha y Hora</TableCell>
                <TableCell>Ubicación</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
          {(tabValue === 0 ? notifications : checkins).map((checkin) => (
            <TableRow key={checkin.id}>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                    <PersonIcon />
                  </Avatar>
                  {checkin.userName}
                </Box>
              </TableCell>
              <TableCell>
                {format(new Date(checkin.timestamp), "d 'de' MMMM 'a las' HH:mm", { locale: es })}
              </TableCell>
              <TableCell>{checkin.location}</TableCell>
              <TableCell>
                {checkin.status === 'pending' ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Chip
                      label="Pendiente"
                      color="warning"
                      size="small"
                    />
                    {(() => {
                      // Calculate remaining time in seconds
                      const createdTime = new Date(checkin.timestamp);
                      const timeoutTime = new Date(createdTime.getTime() + 2 * 60 * 1000); // 2 minutes timeout
                      const remainingMs = timeoutTime - currentTime;
                      const remainingSeconds = Math.max(0, Math.floor(remainingMs / 1000));
                      
                      // Format as MM:SS
                      const minutes = Math.floor(remainingSeconds / 60);
                      const seconds = remainingSeconds % 60;
                      const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                      
                      // Return component with appropriate color
                      return (
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            fontWeight: 'bold',
                            color: remainingSeconds < 30 ? 'error.main' : 
                                  remainingSeconds < 60 ? 'warning.main' : 'text.secondary'
                          }}
                        >
                          {formattedTime}
                        </Typography>
                      );
                    })()} 
                  </Box>
                ) : (
                  <Chip
                    label={checkin.status === 'approved' ? 'Aprobado' : 'Rechazado'}
                    color={checkin.status === 'approved' ? 'success' : 'error'}
                    size="small"
                  />
                )}
              </TableCell>
              <TableCell align="right">
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                  <Tooltip title="Ver detalles">
                    <IconButton 
                      size="small" 
                      color="primary"
                      onClick={() => handleViewCheckin(checkin)}
                    >
                      <EyeIcon />
                    </IconButton>
                  </Tooltip>
                  
                  {checkin.status === 'pending' && (
                    <>
                      <Tooltip title="Aprobar">
                        <IconButton 
                          size="small" 
                          color="success"
                          onClick={() => handleApproveCheckin(checkin.id)}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                      
                      <Tooltip title="Rechazar">
                        <IconButton 
                          size="small" 
                          color="error"
                          onClick={() => handleOpenRejectDialog(checkin)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
          
          {(tabValue === 0 ? notifications : checkins).length === 0 && (
            <TableRow>
              <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                <Typography variant="body1" color="textSecondary">
                  No hay check-ins {tabValue === 0 ? 'pendientes' : ''}
                </Typography>
              </TableCell>
            </TableRow>
          )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* View Check-in Dialog */}
      <Dialog open={viewDialogOpen} onClose={() => setViewDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Detalles del Check-in</DialogTitle>
        <DialogContent dividers>
          {selectedCheckin && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Usuario</Typography>
                <Typography variant="body1">{selectedCheckin.userName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">ID de Usuario</Typography>
                <Typography variant="body1">{selectedCheckin.userId}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Email</Typography>
                <Typography variant="body1">{selectedCheckin.userEmail || 'No especificado'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Fecha y Hora</Typography>
                <Typography variant="body1">
                  {format(new Date(selectedCheckin.timestamp), "d 'de' MMMM 'de' yyyy 'a las' HH:mm:ss", { locale: es })}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Ubicación</Typography>
                <Typography variant="body1">{selectedCheckin.location}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Estado</Typography>
                <Chip
                  label={selectedCheckin.status === 'pending' ? 'Pendiente' : 
                         selectedCheckin.status === 'approved' ? 'Aprobado' : 'Rechazado'}
                  color={selectedCheckin.status === 'pending' ? 'warning' : 
                         selectedCheckin.status === 'approved' ? 'success' : 'error'}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">ID de Check-in</Typography>
                <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>{selectedCheckin.id}</Typography>
              </Grid>
              {selectedCheckin.comments && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Comentarios</Typography>
                  <Typography variant="body1">{selectedCheckin.comments}</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDialogOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      {/* Reject Check-in Dialog */}
      <Dialog open={rejectDialogOpen} onClose={() => setRejectDialogOpen(false)}>
        <DialogTitle>Rechazar Check-in</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            ¿Estás seguro de que deseas rechazar este check-in?
          </Typography>
          <TextField
            label="Motivo (opcional)"
            multiline
            rows={3}
            fullWidth
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            placeholder="Ingresa el motivo del rechazo"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRejectDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleRejectCheckin} color="error" variant="contained">
            Rechazar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Settings Menu */}
      <Menu
        anchorEl={settingsMenuAnchor}
        open={Boolean(settingsMenuAnchor)}
        onClose={() => setSettingsMenuAnchor(null)}
      >
        <MenuItem onClick={() => {
          setSettingsMenuAnchor(null);
          setConfigDialogOpen(true);
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SettingsIcon sx={{ mr: 1 }} />
            Configuración
          </Box>
        </MenuItem>
        <MenuItem onClick={() => {
          setSettingsMenuAnchor(null);
          setChangePasswordDialogOpen(true);
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <VpnKeyIcon sx={{ mr: 1 }} />
            Cambiar Contraseña
          </Box>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ExitToAppIcon sx={{ mr: 1 }} />
            Cerrar Sesión
          </Box>
        </MenuItem>
      </Menu>

      {/* Configuration Dialog */}
      <Dialog open={configDialogOpen} onClose={() => setConfigDialogOpen(false)}>
        <DialogTitle>Configuración</DialogTitle>
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={allowNotifications}
                  onChange={handleToggleNotifications}
                  color="primary"
                />
              }
              label="Permitir notificaciones"
            />
            <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
              Recibirás notificaciones cuando haya nuevos check-ins pendientes
            </Typography>
            

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfigDialogOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      {/* Change Password Dialog */}
      <Dialog open={changePasswordDialogOpen} onClose={() => setChangePasswordDialogOpen(false)}>
        <DialogTitle>Cambiar Contraseña</DialogTitle>
        <DialogContent>
          {passwordError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {passwordError}
            </Alert>
          )}
          <TextField
            margin="dense"
            label="Contraseña Actual"
            type="password"
            fullWidth
            value={passwordData.currentPassword}
            onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Nueva Contraseña"
            type="password"
            fullWidth
            value={passwordData.newPassword}
            onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Confirmar Nueva Contraseña"
            type="password"
            fullWidth
            value={passwordData.confirmPassword}
            onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangePasswordDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleChangePassword} color="primary" variant="contained">
            Cambiar Contraseña
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity="info" 
          sx={{ width: '100%' }}
          action={
            <Button 
              color="inherit" 
              size="small" 
              onClick={() => {
                setSnackbarOpen(false);
                setTabValue(0); // Switch to pending tab
              }}
            >
              Ver
            </Button>
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
    </Box>
  );
};

export default Dashboard;
