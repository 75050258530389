import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  FormGroup,
  FormControlLabel,
  Divider,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Alert,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCity } from '../contexts/CityContext';

const PlansAndPrices = () => {
  const { t, i18n } = useTranslation();
  const { currentCity, convertPrice } = useCity();
  const [annual, setAnnual] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  
  const handleSwitchChange = () => {
    setAnnual(!annual);
  };
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const handleOpenDialog = () => {
    setOpenDialog(true);
    setActiveStep(0);
    setShowSuccess(false);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  
  const handleSubmit = () => {
    setShowSuccess(true);
  };
  
  const individualPlans = [
    {
      name: t('plans.bronze'),
      price: annual ? '24,99' : '29,99',
      priceId: annual ? 'price_bronze_annual' : 'price_bronze_monthly',
      features: [
        { text: t('plans.features.gyms') + ' 100+', included: true },
        { text: '3 ' + t('plans.features.activities'), included: true },
        { text: t('plans.features.schedule') + ' (8:00-16:00)', included: true },
        { text: t('plans.features.guests'), included: false },
      ],
      popular: false,
      color: '#CD7F32',
    },
    {
      name: t('plans.silver'),
      price: annual ? '39,99' : '49,99',
      priceId: annual ? 'price_silver_annual' : 'price_silver_monthly',
      features: [
        { text: t('plans.features.gyms') + ' 500+', included: true },
        { text: '10 ' + t('plans.features.activities'), included: true },
        { text: t('plans.features.schedule') + ' ' + t('plans.features.complete'), included: true },
        { text: t('plans.features.guests') + ' 1/mes', included: true },
      ],
      popular: true,
      color: '#C0C0C0',
    },
    {
      name: t('plans.gold'),
      price: annual ? '59,99' : '79,99',
      priceId: annual ? 'price_gold_annual' : 'price_gold_monthly',
      features: [
        { text: t('plans.features.gyms') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.activities') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.schedule') + ' ' + t('plans.features.complete'), included: true },
        { text: t('plans.features.guests') + ' 3/mes', included: true },
      ],
      popular: false,
      color: '#FFD700',
    },
    {
      name: t('plans.platinum'),
      price: annual ? '79,99' : '99,99',
      priceId: annual ? 'price_platinum_annual' : 'price_platinum_monthly',
      features: [
        { text: t('plans.features.gyms') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.activities') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.schedule') + ' ' + t('plans.features.complete'), included: true },
        { text: t('plans.features.guests') + ' 5/mes', included: true },
      ],
      popular: false,
      color: '#E5E4E2',
    },
    {
      name: t('plans.diamond'),
      price: annual ? '119,99' : '149,99',
      priceId: annual ? 'price_diamond_annual' : 'price_diamond_monthly',
      features: [
        { text: t('plans.features.gyms') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.activities') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.schedule') + ' ' + t('plans.features.complete'), included: true },
        { text: t('plans.features.guests') + ' ' + t('plans.features.unlimited'), included: true },
      ],
      popular: false,
      color: '#B9F2FF',
    },
  ];
  
  const businessPlans = [
    {
      name: 'Startup',
      price: annual ? '19,99' : '24,99',
      pricePerUser: true,
      minUsers: 5,
      maxUsers: 20,
      features: [
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.access500') : 'Acceso a 500+ gimnasios', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.activities5') : '5 actividades incluidas', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.adminPanel') : 'Panel de administración', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.basicReports') : 'Informes básicos', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.emailSupport') : 'Soporte por email', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.corporateEvents') : 'Eventos corporativos', included: false },
      ],
      popular: false,
    },
    {
      name: 'Business',
      price: annual ? '16,99' : '19,99',
      pricePerUser: true,
      minUsers: 21,
      maxUsers: 100,
      features: [
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.access800') : 'Acceso a 800+ gimnasios', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.activitiesAll') : 'Todas las actividades', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.advancedAdminPanel') : 'Panel de administración avanzado', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.detailedReports') : 'Informes detallados', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.prioritySupport') : 'Soporte prioritario', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.corporateEvents2') : '2 eventos corporativos al año', included: true },
      ],
      popular: true,
    },
    {
      name: 'Enterprise',
      price: i18n.language === 'en' ? 'Custom' : 'Personalizado',
      pricePerUser: false,
      minUsers: 101,
      maxUsers: null,
      features: [
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.access1000') : 'Acceso a 1000+ gimnasios', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.activitiesAll') : 'Todas las actividades', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.customAdminPanel') : 'Panel de administración personalizado', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.customReports') : 'Informes a medida', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.dedicatedManager') : 'Gestor de cuenta dedicado', included: true },
        { text: i18n.language === 'en' ? t('pages.plansAndPrices.business.features.corporateEventsUnlimited') : 'Eventos corporativos ilimitados', included: true },
      ],
      popular: false,
    },
  ];
  
  const renderPlans = () => {
    const plans = tabValue === 0 ? individualPlans : businessPlans;
    
    return (
      <Grid container spacing={4}>
        {plans.map((plan, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                position: 'relative',
                overflow: 'visible',
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
                ...(plan.popular && {
                  boxShadow: `0 8px 40px rgba(0, 0, 0, 0.2)`,
                  transform: 'scale(1.05)',
                  zIndex: 1,
                  '&:before': {
                    content: `"${t('plans.mostPopular')}"`,
                    position: 'absolute',
                    top: -12,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bgcolor: 'secondary.main',
                    color: 'white',
                    py: 0.5,
                    px: 2,
                    borderRadius: 20,
                    fontSize: '0.75rem',
                    fontWeight: 'bold',
                  }
                })
              }}
            >
              <CardContent sx={{ p: 4, flexGrow: 1 }}>
                <Typography variant="h5" component="h3" sx={{ 
                  textAlign: 'center', 
                  mb: 1, 
                  fontWeight: 700,
                  color: plan.color
                }}>
                  {plan.name}
                </Typography>
                
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                  <Typography variant="h3" component="p" sx={{ fontWeight: 700 }}>
                    {plan.price === 'Custom' || plan.price === 'Personalizado' 
                      ? t('pages.plansAndPrices.business.customPrice') 
                      : `${convertPrice(plan.price)}${currentCity.currencySymbol}`}
                  </Typography>
                  {plan.pricePerUser && (
                    <Typography variant="body2" component="span" color="text.secondary" sx={{ ml: 1 }}>
                      {t('pages.plansAndPrices.business.perUser')}
                    </Typography>
                  )}
                </Box>
                
                {plan.minUsers && (
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {plan.maxUsers 
                      ? t('pages.plansAndPrices.business.forUsers', { min: plan.minUsers, max: plan.maxUsers })
                      : t('pages.plansAndPrices.business.forUsersUnlimited', { min: plan.minUsers })
                    }
                  </Typography>
                )}
                
                <Divider sx={{ my: 2 }} />
                
                <List sx={{ py: 0 }}>
                  {plan.features.map((feature, idx) => (
                    <ListItem key={idx} sx={{ px: 0, py: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        {feature.included ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="disabled" />
                        )}
                      </ListItemIcon>
                      <ListItemText 
                        primary={feature.text} 
                        sx={{ 
                          '& .MuiListItemText-primary': { 
                            color: feature.included ? 'text.primary' : 'text.disabled' 
                          } 
                        }} 
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              
              <CardActions sx={{ p: 4, pt: 0 }}>
                <Button 
                  fullWidth 
                  variant={plan.popular ? 'contained' : 'outlined'} 
                  color={plan.popular ? 'secondary' : 'primary'}
                  size="large"
                >
                  {plan.price === 'Custom' || plan.price === 'Personalizado' 
                    ? t('pages.plansAndPrices.buttons.contact')
                    : t('pages.plansAndPrices.buttons.choosePlan')
                  }
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  return (
    <>
      <Helmet>
        <title>{t('pages.plansAndPrices.title')} | TuttoGym</title>
        <meta name="description" content={t('pages.plansAndPrices.metaDescription')} />
      </Helmet>
      
      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Box className="section-title">
            <Typography variant="h3" component="h1" fontWeight="bold">
              {t('pages.plansAndPrices.title')}
            </Typography>
            <Typography variant="body1">
              {t('pages.plansAndPrices.subtitle')}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange}
              sx={{ 
                mb: 4,
                '& .MuiTab-root': {
                  px: 4,
                  py: 2,
                },
              }}
            >
              <Tab label={t('pages.plansAndPrices.tabs.individual')} />
              <Tab label={t('pages.plansAndPrices.tabs.business')} />
            </Tabs>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch 
                    checked={annual} 
                    onChange={handleSwitchChange} 
                    color="primary"
                  />
                }
                label={annual ? 
                  t('pages.plansAndPrices.billing.annual') + ' (' + t('pages.plansAndPrices.billing.saveText') + ')' :
                  t('pages.plansAndPrices.billing.monthly')
                }
              />
            </FormGroup>
          </Box>
          
          {renderPlans()}
          
          {/* Botón para solicitar que la empresa pague la membresía */}
          <Box sx={{ 
            mt: 6, 
            mb: 4, 
            textAlign: 'center', 
            bgcolor: 'primary.light', 
            color: 'white',
            py: 4, 
            px: 3, 
            borderRadius: 2,
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
          }}>
            <Typography variant="h5" component="h3" gutterBottom fontWeight="bold">
              {t('pages.plansAndPrices.companyPayment.title')}
            </Typography>
            <Button 
              variant="contained" 
              color="secondary" 
              size="large"
              onClick={handleOpenDialog}
              sx={{ 
                mt: 2,
                px: 4,
                py: 1,
                fontWeight: 'bold'
              }}
            >
              {t('pages.plansAndPrices.companyPayment.button')}
            </Button>
          </Box>
          
          <Box sx={{ mt: 8, textAlign: 'center' }}>
            <Typography variant="h5" component="h2" gutterBottom>
              {t('pages.plansAndPrices.customPlan.title')}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('pages.plansAndPrices.customPlan.subtitle')}
            </Typography>
            <Button variant="outlined" color="primary" size="large">
              {t('pages.plansAndPrices.customPlan.button')}
            </Button>
          </Box>
          
          <Box sx={{ mt: 8 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              {t('pages.plansAndPrices.faq.title')}
            </Typography>
            
            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  {t('pages.plansAndPrices.faq.questions.0.question')}
                </Typography>
                <Typography variant="body1" paragraph>
                  {t('pages.plansAndPrices.faq.questions.0.answer')}
                </Typography>
                
                <Typography variant="h6" gutterBottom>
                  {t('pages.plansAndPrices.faq.questions.1.question')}
                </Typography>
                <Typography variant="body1" paragraph>
                  {t('pages.plansAndPrices.faq.questions.1.answer')}
                </Typography>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  {t('pages.plansAndPrices.faq.questions.2.question')}
                </Typography>
                <Typography variant="body1" paragraph>
                  {t('pages.plansAndPrices.faq.questions.2.answer')}
                </Typography>
                
                <Typography variant="h6" gutterBottom>
                  {t('pages.plansAndPrices.faq.questions.3.question')}
                </Typography>
                <Typography variant="body1" paragraph>
                  {t('pages.plansAndPrices.faq.questions.3.answer')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      
      {/* Diálogo para solicitar que la empresa pague la membresía */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {activeStep === 0 
            ? t('pages.plansAndPrices.dialog.title.step1')
            : t('pages.plansAndPrices.dialog.title.step2')}
        </DialogTitle>
        <DialogContent>
          {showSuccess ? (
            <Alert severity="success" sx={{ mt: 2 }}>
              {t('pages.plansAndPrices.dialog.success')}
            </Alert>
          ) : (
            <>
              <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                <Step>
                  <StepLabel>{t('pages.plansAndPrices.dialog.steps.company')}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{t('pages.plansAndPrices.dialog.steps.contact')}</StepLabel>
                </Step>
              </Stepper>
              
              {activeStep === 0 ? (
                <TextField
                  autoFocus
                  margin="dense"
                  id="companyName"
                  label={t('pages.plansAndPrices.dialog.labels.company')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              ) : (
                <TextField
                  autoFocus
                  margin="dense"
                  id="contactEmail"
                  label={t('pages.plansAndPrices.dialog.labels.email')}
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  required
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!showSuccess && (
            <>
              <Button onClick={handleCloseDialog}>
                {t('common.cancel')}
              </Button>
              {activeStep === 0 ? (
                <Button 
                  onClick={handleNext} 
                  variant="contained" 
                  color="primary"
                  disabled={!companyName}
                >
                  {t('common.next')}
                </Button>
              ) : (
                <>
                  <Button onClick={handleBack}>
                    {t('common.back')}
                  </Button>
                  <Button 
                    onClick={handleSubmit} 
                    variant="contained" 
                    color="primary"
                    disabled={!contactEmail}
                  >
                    {t('common.submit')}
                  </Button>
                </>
              )}
            </>
          )}
          {showSuccess && (
            <Button onClick={handleCloseDialog} variant="contained" color="primary">
              {t('common.close')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlansAndPrices; 