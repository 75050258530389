import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import HeroSection from '../components/HeroSection';
import Benefits from '../components/Benefits';
import Plans from '../components/Plans';
import FAQ from '../components/FAQ';

const Home = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <>
      <Helmet>
        <title>TuttoGym - {t('hero.subtitle')}</title>
        <meta name="description" content="TuttoGym - Todas tus actividades físicas en un solo lugar. Accede a múltiples gimnasios y actividades con una sola membresía." />
        <html lang={i18n.language} />
      </Helmet>
      
      <HeroSection />
      <Benefits />
      <Plans />
      <FAQ />
    </>
  );
};

export default Home; 