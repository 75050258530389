import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import workoutBackground from '../images/workout.jpg';
import { useCity } from '../contexts/CityContext';

const Plans = () => {
  const { t } = useTranslation();
  const { currentCity, convertPrice } = useCity();
  const [startIndex, setStartIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  
  const plans = [
    {
      name: t('plans.bronze'),
      price: '29,99',
      features: [
        { text: t('plans.features.gyms') + ' 100+', included: true },
        { text: '3 ' + t('plans.features.activities'), included: true },
        { text: t('plans.features.schedule') + ' (8:00-16:00)', included: true },
        { text: t('plans.features.guests'), included: false },
      ],
      popular: false,
      color: '#CD7F32',
    },
    {
      name: t('plans.silver'),
      price: '49,99',
      features: [
        { text: t('plans.features.gyms') + ' 500+', included: true },
        { text: '10 ' + t('plans.features.activities'), included: true },
        { text: t('plans.features.schedule') + ' ' + t('plans.features.complete'), included: true },
        { text: t('plans.features.guests') + ' 1/mes', included: true },
      ],
      popular: true,
      color: '#C0C0C0',
    },
    {
      name: t('plans.gold'),
      price: '79,99',
      features: [
        { text: t('plans.features.gyms') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.activities') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.schedule') + ' ' + t('plans.features.complete'), included: true },
        { text: t('plans.features.guests') + ' 3/mes', included: true },
      ],
      popular: false,
      color: '#FFD700',
    },
    {
      name: t('plans.platinum'),
      price: '99,99',
      features: [
        { text: t('plans.features.gyms') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.activities') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.schedule') + ' ' + t('plans.features.complete'), included: true },
        { text: t('plans.features.guests') + ' 5/mes', included: true },
      ],
      popular: false,
      color: '#E5E4E2',
    },
    {
      name: t('plans.diamond'),
      price: '149,99',
      features: [
        { text: t('plans.features.gyms') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.activities') + ' ' + t('plans.features.all'), included: true },
        { text: t('plans.features.schedule') + ' ' + t('plans.features.complete'), included: true },
        { text: t('plans.features.guests') + ' ' + t('plans.features.unlimited'), included: true },
      ],
      popular: false,
      color: '#B9F2FF',
    },
  ];

  const visiblePlans = plans.slice(startIndex, startIndex + 3);
  
  const handleNext = () => {
    if (animating) return;
    if (startIndex + 3 < plans.length) {
      setAnimating(true);
      setStartIndex(startIndex + 1);
    }
  };
  
  const handlePrev = () => {
    if (animating) return;
    if (startIndex > 0) {
      setAnimating(true);
      setStartIndex(startIndex - 1);
    }
  };
  
  useEffect(() => {
    if (animating) {
      const timer = setTimeout(() => {
        setAnimating(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animating]);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <Box 
      sx={{ 
        py: 8, 
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${workoutBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          filter: 'brightness(0.4)',
          zIndex: -1,
        }
      }}
    >
      <Container maxWidth="lg">
        <Box className="section-title" sx={{ color: 'white', textAlign: 'center', mb: 4 }}>
          <Typography variant="h2" component="h2" sx={{ textShadow: '0 2px 10px rgba(0,0,0,0.5)' }}>
            {t('plans.title')}
          </Typography>
          <Typography variant="body1" sx={{ textShadow: '0 2px 10px rgba(0,0,0,0.5)', color: 'white' }}>
            {t('plans.subtitle')}
          </Typography>
        </Box>
        
        <Box sx={{ position: 'relative', mt: 2, px: { xs: 0, md: 6 } }}>
          {startIndex > 0 && (
            <IconButton 
              onClick={handlePrev}
              sx={{ 
                position: 'absolute', 
                left: 0, 
                top: '50%', 
                transform: 'translateY(-50%)',
                zIndex: 2,
                bgcolor: 'background.paper',
                boxShadow: 2,
                '&:hover': { bgcolor: 'background.default' }
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          
          {startIndex + (isMobile ? 1 : 3) < plans.length && (
            <IconButton 
              onClick={handleNext}
              sx={{ 
                position: 'absolute', 
                right: 0, 
                top: '50%', 
                transform: 'translateY(-50%)',
                zIndex: 2,
                bgcolor: 'background.paper',
                boxShadow: 2,
                '&:hover': { bgcolor: 'background.default' }
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}
          
          <Grid 
            container 
            spacing={4} 
            sx={{ 
              transition: 'transform 0.5s ease-in-out',
              transform: animating ? 'scale(0.98)' : 'scale(1)',
              opacity: animating ? 0.8 : 1,
            }}
          >
            {visiblePlans.slice(0, isMobile ? 1 : 3).map((plan, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card 
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'visible',
                    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
                    ...(plan.popular && {
                      boxShadow: `0 8px 40px rgba(0, 0, 0, 0.3)`,
                      transform: 'scale(1.05)',
                      zIndex: 1,
                      '&:before': {
                        content: `"${t('plans.mostPopular')}"`,
                        position: 'absolute',
                        top: -12,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bgcolor: 'secondary.main',
                        color: 'white',
                        py: 0.5,
                        px: 2,
                        borderRadius: 20,
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                      }
                    })
                  }}
                >
                  <CardContent sx={{ p: 4, flexGrow: 1 }}>
                    <Typography variant="h5" component="h3" sx={{ 
                      textAlign: 'center', 
                      mb: 1, 
                      fontWeight: 700,
                      color: plan.color
                    }}>
                      {plan.name}
                    </Typography>
                    
                    <Box sx={{ textAlign: 'center', mb: 3 }}>
                      <Typography variant="h3" component="p" sx={{ fontWeight: 700 }}>
                        {convertPrice(plan.price)}{currentCity.currencySymbol}
                        <Typography component="span" variant="body1" sx={{ ml: 0.5 }}>
                          {t('plans.monthly')}
                        </Typography>
                      </Typography>
                    </Box>
                    
                    <Divider sx={{ mb: 2 }} />
                    
                    <List sx={{ mb: 2 }}>
                      {plan.features.map((feature, idx) => (
                        <ListItem key={idx} sx={{ px: 0, py: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            {feature.included ? (
                              <CheckCircleIcon sx={{ color: 'success.main' }} />
                            ) : (
                              <CancelIcon sx={{ color: 'text.disabled' }} />
                            )}
                          </ListItemIcon>
                          <ListItemText 
                            primary={feature.text} 
                            sx={{ 
                              '& .MuiListItemText-primary': { 
                                color: feature.included ? 'text.primary' : 'text.disabled' 
                              } 
                            }} 
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                  
                  <CardActions sx={{ p: 4, pt: 0 }}>
                    <Button 
                      fullWidth 
                      variant={plan.popular ? 'contained' : 'outlined'} 
                      color={plan.popular ? 'secondary' : 'primary'}
                      size="large"
                    >
                      {t('plans.choosePlan')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            {plans.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  mx: 0.5,
                  bgcolor: index >= startIndex && index < startIndex + 3 ? 'white' : 'rgba(255, 255, 255, 0.5)',
                  cursor: 'pointer',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                }}
                onClick={() => {
                  if (index <= plans.length - 3 && !animating) {
                    setAnimating(true);
                    setStartIndex(index);
                  }
                }}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Plans; 