import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Paper,
  Grid,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { saveUserSignup } from '../services/api';
import ModalPortal from './ModalPortal';

// Lista de deportes disponibles para seleccionar
const availableSports = [
  { id: 'gym', name: 'Gimnasio' },
  { id: 'yoga', name: 'Yoga' },
  { id: 'pilates', name: 'Pilates' },
  { id: 'swimming', name: 'Natación' },
  { id: 'running', name: 'Running' },
  { id: 'crossfit', name: 'Crossfit' },
  { id: 'padel', name: 'Pádel' },
  { id: 'tennis', name: 'Tenis' },
  { id: 'cycling', name: 'Ciclismo' },
  { id: 'martial_arts', name: 'Artes Marciales' },
  { id: 'hiit', name: 'HIIT' },
  { id: 'functional', name: 'Entrenamiento Funcional' }
];

const SignupForm = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [sessionId, setSessionId] = useState('');
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [loading, setLoading] = useState(false);
  
  // Estado del formulario
  const [formData, setFormData] = useState({
    email: '',
    selectedSports: {},
    otherSports: ''
  });
  
  const [errors, setErrors] = useState({});
  
  // Pasos del formulario
  const steps = i18n.language === 'en' 
    ? ['Email', 'Sports', 'Confirmation'] 
    : ['Email', 'Deportes', 'Confirmación'];
  
  // Generar ID de sesión y timestamp cuando se abre el formulario
  useEffect(() => {
    if (open) {
      if (!sessionId) {
        const newSessionId = 'session_' + new Date().getTime() + '_' + Math.random().toString(36).substring(2, 10);
        setSessionId(newSessionId);
        setStartTimestamp(new Date().toISOString());
      }
      
      setActiveStep(0);
    }
  }, [open, sessionId]);
  
  // Manejar cambios en los campos de texto
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    // Limpiar error cuando se completa el campo
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
  };
  
  // Manejar cambios en los checkboxes de deportes
  const handleSportChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      selectedSports: {
        ...formData.selectedSports,
        [name]: checked
      }
    });
  };
  
  // Validar el paso actual
  const validateStep = () => {
    const newErrors = {};
    
    if (activeStep === 0) {
      // Validar email
      if (!formData.email) {
        newErrors.email = i18n.language === 'en' 
          ? 'Email is required' 
          : 'Email es requerido';
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = i18n.language === 'en' 
          ? 'Invalid email format' 
          : 'Formato de email inválido';
      }
    } else if (activeStep === 1) {
      // Validar que al menos se seleccione un deporte
      const hasSports = Object.values(formData.selectedSports).some(value => value) || 
                       formData.otherSports.trim().length > 0;
      
      if (!hasSports) {
        newErrors.sports = i18n.language === 'en' 
          ? 'Please select at least one sport or specify other sports' 
          : 'Por favor selecciona al menos un deporte o especifica otros';
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  // Avanzar al siguiente paso
  const handleNext = () => {
    if (validateStep()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  
  // Retroceder al paso anterior
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  
  // Enviar el formulario
  const handleSubmit = async () => {
    try {
      setLoading(true);
      
      // Crear una lista plana de deportes seleccionados
      const sports = Object.entries(formData.selectedSports)
        .filter(([_, selected]) => selected)
        .map(([sportId, _]) => {
          const sport = availableSports.find(s => s.id === sportId);
          return sport ? sport.name : sportId;
        });
      
      // Datos a enviar
      const userData = {
        id_session: sessionId,
        timestamp: startTimestamp,
        email: formData.email,
        sports: sports,
        otherSports: formData.otherSports.trim()
      };
      
      try {
        // Enviar datos al backend
        const response = await saveUserSignup(userData);
        
        // Avanzar al paso de confirmación
        setActiveStep(2);
      } catch (error) {
        console.error('Error submitting form:', error);
        
        // Si el error es 404, avanzar igualmente al paso de confirmación
        if (error.response && error.response.status === 404) {
          console.log('API endpoint not found (404), continuing to confirmation step');
          setActiveStep(2);
        } else {
          // Para otros errores, mostrar alerta
          alert(i18n.language === 'en' 
            ? 'An error occurred while processing your request. Please try again.' 
            : 'Ocurrió un error al procesar tu solicitud. Por favor intenta nuevamente.');
        }
      }
      
      setLoading(false);
    } catch (error) {
      console.error('General form submission error:', error);
      setLoading(false);
      
      // Avanzar al paso de confirmación incluso si hay error
      setActiveStep(2);
    }
  };
  
  // Contenido de cada paso
  const getStepContent = (step) => {
    switch (step) {
      case 0: // Email
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {i18n.language === 'en' ? 'Enter your email' : 'Introduce tu email'}
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              {i18n.language === 'en' 
                ? 'We\'ll send you information about our services and how to get started.' 
                : 'Te enviaremos información sobre nuestros servicios y cómo comenzar.'}
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              name="email"
              label={i18n.language === 'en' ? 'Email' : 'Correo electrónico'}
              type="email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleTextChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ mt: 2 }}
            />
          </Box>
        );
        
      case 1: // Deportes
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {i18n.language === 'en' ? 'What sports do you practice?' : '¿Qué deportes practicas?'}
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              {i18n.language === 'en' 
                ? 'Select all that apply. This helps us recommend the best gyms for you.' 
                : 'Selecciona todos los que apliquen. Esto nos ayuda a recomendarte los mejores gimnasios.'}
            </Typography>
            
            {errors.sports && (
              <Typography color="error" variant="caption" sx={{ display: 'block', mb: 2 }}>
                {errors.sports}
              </Typography>
            )}
            
            <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.paper' }}>
              <FormControl component="fieldset" fullWidth>
                <FormGroup>
                  <Grid container spacing={2}>
                    {availableSports.map((sport) => (
                      <Grid item xs={6} sm={4} key={sport.id}>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={!!formData.selectedSports[sport.id]} 
                              onChange={handleSportChange} 
                              name={sport.id} 
                            />
                          }
                          label={sport.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </FormControl>
            </Paper>
            
            <TextField
              margin="dense"
              id="otherSports"
              name="otherSports"
              label={i18n.language === 'en' ? 'Other sports (comma separated)' : 'Otros deportes (separados por coma)'}
              placeholder={i18n.language === 'en' ? 'E.g. Climbing, Dancing, Volleyball' : 'Ej. Escalada, Baile, Voleibol'}
              fullWidth
              variant="outlined"
              value={formData.otherSports}
              onChange={handleTextChange}
              sx={{ mt: 3 }}
            />
          </Box>
        );
        
      case 2: // Confirmación
        return (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 80, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              {i18n.language === 'en' ? 'Thank you!' : '¡Gracias!'}
            </Typography>
            <Typography variant="body1" paragraph>
              {i18n.language === 'en'
                ? 'We\'ve received your information. TuttoGym will be launching on July 7, 2025, and we\'ll contact you before the launch with exclusive early access opportunities.'
                : 'Hemos recibido tu información. TuttoGym se lanzará el 7 de julio de 2025, y nos pondremos en contacto contigo antes del lanzamiento con oportunidades exclusivas de acceso anticipado.'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {i18n.language === 'en'
                ? 'A confirmation email has been sent to ' + formData.email
                : 'Un correo de confirmación ha sido enviado a ' + formData.email}
            </Typography>
          </Box>
        );
        
      default:
        return 'Unknown step';
    }
  };
  
  const handleDialogClose = () => {
    onClose();
  };
  
  return (
    <ModalPortal open={open}>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden',
          },
        }}
        container={document.getElementById('modal-root')}
        disableEnforceFocus
        hideBackdrop={false}
      >
        <DialogTitle sx={{ 
          bgcolor: 'primary.main', 
          color: 'white',
          position: 'relative',
          py: 2
        }}>
          {i18n.language === 'en' ? 'Start your fitness journey' : 'Comienza tu viaje fitness'}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 3 }}>
            {getStepContent(activeStep)}
          </Box>
        </DialogContent>
        <DialogActions 
          sx={{ 
            justifyContent: activeStep === 2 ? 'center' : 'space-between',
            py: 3,
            px: 3,
            borderTop: '1px solid',
            borderColor: 'divider'
          }}
        >
          {activeStep === 2 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleDialogClose}
            >
              {i18n.language === 'en' ? 'Close' : 'Cerrar'}
            </Button>
          ) : (
            <>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                {i18n.language === 'en' ? 'Back' : 'Atrás'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={activeStep === 1 ? handleSubmit : handleNext}
                disabled={loading}
              >
                {loading ? (
                  i18n.language === 'en' ? 'Processing...' : 'Procesando...'
                ) : activeStep === 1 ? (
                  i18n.language === 'en' ? 'Submit' : 'Enviar'
                ) : (
                  i18n.language === 'en' ? 'Next' : 'Siguiente'
                )}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </ModalPortal>
  );
};

export default SignupForm; 