import { createTheme } from '@mui/material/styles';

// Colores que representan fitness:
// - Verde energético: #4CAF50 (representa vitalidad y energía)
// - Azul intenso: #1976D2 (representa fuerza y determinación)
// - Naranja vibrante: #FF5722 (representa dinamismo)
// - Gris oscuro: #333333 (para contraste y profesionalidad)

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976D2', // Azul intenso como color principal
      light: '#42a5f5',
      dark: '#1565c0',
    },
    secondary: {
      main: '#FF5722', // Naranja vibrante como color secundario
      light: '#ff8a65',
      dark: '#e64a19',
    },
    success: {
      main: '#4CAF50', // Verde energético para éxito
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#757575',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          padding: '10px 20px',
        },
        containedPrimary: {
          boxShadow: '0 4px 10px rgba(25, 118, 210, 0.3)',
          '&:hover': {
            boxShadow: '0 6px 15px rgba(25, 118, 210, 0.4)',
          },
        },
        containedSecondary: {
          boxShadow: '0 4px 10px rgba(255, 87, 34, 0.3)',
          '&:hover': {
            boxShadow: '0 6px 15px rgba(255, 87, 34, 0.4)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.15)',
          },
        },
      },
    },
  },
});

export default theme; 