import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Paper,
  IconButton,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GetAppIcon from '@mui/icons-material/GetApp';
import { saveInterestedCompany, saveInterestedPartner } from '../services/api';
import ModalPortal from './ModalPortal';

const employeeSizeRanges = [
  { value: '<10', label: '<10' },
  { value: '<100', label: '<100' },
  { value: '<500', label: '<500' },
  { value: '<1000', label: '<1000' },
  { value: '<3000', label: '<3000' },
  { value: '<10000', label: '<10000' },
  { value: '>10000', label: '>10000' },
  { value: 'other', label: 'other' },
];

const clientSizeRanges = [
  { value: '<10', label: '<10' },
  { value: '<50', label: '<50' },
  { value: '<100', label: '<100' },
  { value: '<500', label: '<500' },
  { value: '<1000', label: '<1000' },
  { value: '<2500', label: '<2500' },
  { value: '<5000', label: '<5000' },
  { value: '<10000', label: '<10000' },
  { value: '>10000', label: '>10000' },
];

const RequestInfoForm = ({ open, onClose, formType }) => {
  const { t, i18n } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    companyName: '',
    employeeSize: '',
    clientSize: '',
    avgPricePerClient: '',
    phone: '',
    additionalInfo: '',
  });
  const [errors, setErrors] = useState({});
  const [estimatedPrice, setEstimatedPrice] = useState(0);

  // Define steps based on form type and language
  const steps = formType === 'partner' 
    ? (i18n.language === 'en' 
       ? t('pages.partners.requestForm.steps', { returnObjects: true }) 
       : ['Email', 'Gimnasio', 'Presupuesto', 'Información Adicional', 'Confirmación'])
    : t('pages.business.requestForm.steps', { returnObjects: true }) || 
      ['Email', 'Empresa', 'Presupuesto', 'Información Adicional', 'Confirmación'];

  // Generate a session ID and timestamp when the form is opened
  useEffect(() => {
    if (open) {
      // Generate a session ID if none exists
      if (!sessionId) {
        const newSessionId = 'session_' + new Date().getTime() + '_' + Math.random().toString(36).substring(2, 10);
        setSessionId(newSessionId);
        setStartTimestamp(new Date().toISOString());
      }
      
      // Reset form data when reopening with a new form type
      setActiveStep(0);
    }
  }, [open, sessionId, formType]);

  // Calculate price based on employee/client size
  useEffect(() => {
    if (activeStep === 2 && (formData.employeeSize || formData.clientSize)) {
      setLoading(true);
      // Simulate calculation delay
      const timer = setTimeout(() => {
        let basePrice = 0;
        
        if (formType === 'partner') {
          switch(formData.clientSize) {
            case '<10':
              basePrice = 100;
              break;
            case '<50':
              basePrice = 150;
              break;
            case '<100':
              basePrice = 200;
              break;
            case '<500':
              basePrice = 250;
              break;
            case '<1000':
              basePrice = 300;
              break;
            case '<2500':
              basePrice = 700;
              break;
            case '<5000':
              basePrice = 1500;
              break;
            case '<10000':
              basePrice = 3000;
              break;
            case '>10000':
              basePrice = 6000;
              break;
            default:
              basePrice = 1000;
          }
        } else {
          switch(formData.employeeSize) {
            case '<10':
              basePrice = 200;
              break;
            case '<100':
              basePrice = 1500;
              break;
            case '<500':
              basePrice = 5000;
              break;
            case '<1000':
              basePrice = 8000;
              break;
            case '<3000':
              basePrice = 15000;
              break;
            case '<10000':
              basePrice = 30000;
              break;
            case '>10000':
              basePrice = 50000;
              break;
            default:
              basePrice = 1000;
          }
        }
        
        setEstimatedPrice(basePrice);
        setLoading(false);
      }, 3000); // 3 seconds delay for animation
      
      return () => clearTimeout(timer);
    }
  }, [activeStep, formData.employeeSize, formData.clientSize, formType]);

  // Send form data to the server when fields change or the form is closed
  const saveFormProgress = async () => {
    try {
      if (!sessionId || !startTimestamp) return;
      
      if (formType === 'partner') {
        // Convert client size to a numeric value for storage
        let totalCustomers = null;
        if (formData.clientSize) {
          switch (formData.clientSize) {
            case '>10000': totalCustomers = 1000; break;
            case '<10': totalCustomers = 10; break;
            case '<50': totalCustomers = 50; break;
            case '<100': totalCustomers = 100; break;
            case '<500': totalCustomers = 500; break;
            case '<1000': totalCustomers = 1000; break;
            case '<2500': totalCustomers = 2500; break;
            case '<5000': totalCustomers = 5000; break;
            case '<10000': totalCustomers = 10000; break;
            default: totalCustomers = null;
          }
        }
        
        // Add partner-specific information to commentary
        let commentary = formData.additionalInfo || '';
        
        // Map form data to partner database schema
        const partnerData = {
          id_session: sessionId,
          timestamp: startTimestamp,
          email: formData.email || null,
          partner_name: formData.companyName || null,
          total_customers: totalCustomers,
          monthly_price: formData.avgPricePerClient ? parseFloat(formData.avgPricePerClient) : null,
          phone: formData.phone || null,
          commentary: commentary || null
        };
        
        // Save to database using partner API
        const result = await saveInterestedPartner(partnerData);
        console.log('Partner form progress saved', result);
      } else {
        // Convert employee size to a numeric value for storage
        let totalEmployees = null;
        if (formType !== 'partner' && formData.employeeSize) {
          switch (formData.employeeSize) {
            case '>10000': totalEmployees = 10000; break;
            case '<10': totalEmployees = 10; break;
            case '<100': totalEmployees = 100; break;
            case '<500': totalEmployees = 500; break;
            case '<1000': totalEmployees = 1000; break;
            case '<3000': totalEmployees = 3000; break;
            case '<10000': totalEmployees = 10000; break;
            default: totalEmployees = null;
          }
        }
        
        // Build commentary with additional info and form-specific details
        let commentary = formData.additionalInfo || '';
        
        // Map form data to database schema
        const companyData = {
          id_session: sessionId,
          timestamp: startTimestamp,
          email: formData.email || null,
          company_name: formData.companyName || null,
          total_employees: totalEmployees,
          phone: formData.phone || null,
          commentary: commentary || null
        };
        
        // Save to database with upsert logic handled by the backend
        const result = await saveInterestedCompany(companyData);
        console.log('Form progress saved', result);
      }
    } catch (error) {
      console.error('Error saving form progress:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    // Clear error when field is filled
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
    
    // Save progress after field change with a short debounce
    if (sessionId) {
      // Only debounce text fields, not selection fields
      const debounceTime = ['email', 'companyName', 'phone', 'additionalInfo', 'avgPricePerClient'].includes(name) ? 800 : 0;
      clearTimeout(window.saveProgressTimeout);
      window.saveProgressTimeout = setTimeout(() => {
        saveFormProgress();
      }, debounceTime);
    }
  };

  const validateStep = () => {
    const newErrors = {};
    
    if (activeStep === 0) {
      if (!formData.email) {
        newErrors.email = formType === 'partner' 
          ? (i18n.language === 'en' 
            ? t('pages.partners.requestForm.emailStep.emailRequired') 
            : 'Email es requerido') 
          : t('pages.business.requestForm.emailStep.emailRequired', 'Email is required');
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = formType === 'partner' 
          ? (i18n.language === 'en' 
            ? t('pages.partners.requestForm.emailStep.emailInvalid') 
            : 'Formato de email inválido') 
          : t('pages.business.requestForm.emailStep.emailInvalid', 'Invalid email format');
      }
    } else if (activeStep === 1) {
      if (!formData.companyName) {
        newErrors.companyName = formType === 'partner' 
          ? (i18n.language === 'en' 
            ? t('pages.partners.requestForm.companyStep.companyNameRequired') 
            : 'Nombre del gimnasio es requerido') 
          : t('pages.business.requestForm.companyStep.companyNameRequired', 'Company name is required');
      }
      
      if (formType === 'partner') {
        if (!formData.clientSize) {
          newErrors.clientSize = i18n.language === 'en' 
            ? t('pages.partners.requestForm.companyStep.clientSizeRequired') 
            : 'Por favor seleccione el número de clientes mensuales';
        }
        if (!formData.avgPricePerClient || isNaN(formData.avgPricePerClient) || parseFloat(formData.avgPricePerClient) <= 0) {
          newErrors.avgPricePerClient = i18n.language === 'en' 
            ? t('pages.partners.requestForm.companyStep.avgPriceRequired') 
            : 'Por favor ingrese un precio promedio válido';
        }
      } else {
        if (!formData.employeeSize) {
          newErrors.employeeSize = t('pages.business.requestForm.companyStep.employeeSizeRequired', 'Please select employee size');
        }
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      // Save progress before moving to next step
      saveFormProgress().then(() => {
        setActiveStep((prevStep) => prevStep + 1);
      });
    }
  };

  const handleBack = () => {
    // Save progress before going back
    saveFormProgress().then(() => {
      setActiveStep((prevStep) => prevStep - 1);
    });
  };

  const handleSubmit = async () => {
    // Save the final form data
    await saveFormProgress();
    
    // Here you would typically send the data to your backend
    console.log('Form submitted:', formData);
    
    // Move to final step
    setActiveStep(4);
  };

  const handleDownloadQuote = () => {
    // In a real application, this would generate and download a PDF
    alert(t('pages.business.requestForm.quoteStep.downloadSuccess', 'Quote downloaded!'));
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {formType === 'partner' 
               ? (i18n.language === 'en' 
                  ? t('pages.partners.requestForm.emailStep.title') 
                  : 'Introduce tu email') 
               : t('pages.business.requestForm.emailStep.title', 'Introduce tu email')}
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              name="email"
              label={formType === 'partner' 
                    ? (i18n.language === 'en' 
                       ? t('pages.partners.requestForm.emailStep.emailLabel') 
                       : 'Correo electrónico') 
                    : t('pages.business.requestForm.emailStep.emailLabel', 'Correo electrónico')}
              type="email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ mt: 2 }}
            />
          </Box>
        );
      case 1:
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {formType === 'partner' 
               ? (i18n.language === 'en' 
                  ? t('pages.partners.requestForm.companyStep.title') 
                  : 'Información del gimnasio') 
               : t('pages.business.requestForm.companyStep.title', 'Información de la empresa')}
            </Typography>
            <TextField
              margin="dense"
              id="companyName"
              name="companyName"
              label={formType === 'partner' 
                    ? (i18n.language === 'en' 
                       ? t('pages.partners.requestForm.companyStep.companyNameLabel') 
                       : 'Nombre del gimnasio') 
                    : t('pages.business.requestForm.companyStep.companyNameLabel', 'Nombre de la empresa')}
              type="text"
              fullWidth
              variant="outlined"
              value={formData.companyName}
              onChange={handleChange}
              error={!!errors.companyName}
              helperText={errors.companyName}
              sx={{ mt: 2 }}
            />
            
            {formType === 'partner' ? (
              <>
                <FormControl 
                  fullWidth 
                  variant="outlined" 
                  sx={{ mt: 3 }}
                  error={!!errors.clientSize}
                >
                  <InputLabel id="client-size-label">
                    {i18n.language === 'en' 
                     ? t('pages.partners.requestForm.companyStep.clientSizeLabel') 
                     : 'Número de clientes mensuales'}
                  </InputLabel>
                  <Select
                    labelId="client-size-label"
                    id="clientSize"
                    name="clientSize"
                    value={formData.clientSize}
                    onChange={handleChange}
                    label={i18n.language === 'en' 
                           ? t('pages.partners.requestForm.companyStep.clientSizeLabel') 
                           : 'Número de clientes mensuales'}
                  >
                    {clientSizeRanges.map((range) => (
                      <MenuItem key={range.value} value={range.value}>
                        {range.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.clientSize && (
                    <Typography color="error" variant="caption" sx={{ mt: 1, ml: 2 }}>
                      {errors.clientSize}
                    </Typography>
                  )}
                </FormControl>
                
                <TextField
                  margin="dense"
                  id="avgPricePerClient"
                  name="avgPricePerClient"
                  label={i18n.language === 'en' 
                        ? t('pages.partners.requestForm.companyStep.avgPriceLabel') 
                        : 'Cuota promedio mensual por cliente (€)'}
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={formData.avgPricePerClient}
                  onChange={handleChange}
                  error={!!errors.avgPricePerClient}
                  helperText={errors.avgPricePerClient}
                  sx={{ mt: 2 }}
                  InputProps={{
                    startAdornment: <span>€</span>,
                  }}
                />
              </>
            ) : (
              <FormControl fullWidth margin="normal" error={!!errors.employeeSize} required>
                <InputLabel>
                  {t('pages.business.requestForm.companyStep.employeeSizeLabel', 'Number of Employees')}
                </InputLabel>
                <Select
                  name="employeeSize"
                  value={formData.employeeSize}
                  onChange={handleChange}
                  label={t('pages.business.requestForm.companyStep.employeeSizeLabel', 'Number of Employees')}
                >
                  {employeeSizeRanges.map((range) => (
                    <MenuItem key={range.value} value={range.value}>
                      {range.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.employeeSize && (
                  <Typography variant="caption" color="error">
                    {errors.employeeSize}
                  </Typography>
                )}
              </FormControl>
            )}
            <TextField
              fullWidth
              label={t('pages.business.requestForm.companyStep.phoneLabel', 'Phone (optional)')}
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              margin="normal"
            />
          </Box>
        );
      case 2:
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {formType === 'partner' 
               ? (i18n.language === 'en' 
                  ? t('pages.partners.requestForm.quoteStep.title') 
                  : 'Calculando tu presupuesto...') 
               : t('pages.business.requestForm.quoteStep.title', 'Calculando tu presupuesto...')}
            </Typography>
            
            {loading ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
                <CircularProgress size={60} />
                <Typography variant="body1" sx={{ mt: 3, textAlign: 'center' }}>
                  {formType === 'partner' 
                   ? (i18n.language === 'en' 
                      ? t('pages.partners.requestForm.quoteStep.calculating') 
                      : 'Por favor espera mientras calculamos un presupuesto personalizado para tu gimnasio...') 
                   : t('pages.business.requestForm.quoteStep.calculating', 'Por favor espera mientras calculamos un presupuesto personalizado para tu empresa...')}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ py: 3 }}>
                <Paper elevation={3} sx={{ p: 3, textAlign: 'center', borderRadius: 2 }}>
                  <Typography variant="h5" color="primary" gutterBottom>
                    {formType === 'partner' 
                     ? (i18n.language === 'en' 
                        ? t('pages.partners.requestForm.quoteStep.quoteTitle') 
                        : 'Tu Presupuesto Estimado') 
                     : t('pages.business.requestForm.quoteStep.quoteTitle', 'Tu Presupuesto Estimado')}
                  </Typography>
                  <Typography variant="h3" fontWeight="bold" sx={{ my: 2 }}>
                    €{estimatedPrice.toLocaleString()}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {formType === 'partner' 
                     ? (i18n.language === 'en' 
                        ? t('pages.partners.requestForm.quoteStep.quoteDescription') 
                        : 'Este es un precio anual estimado basado en el tamaño de tu gimnasio.') 
                     : t('pages.business.requestForm.quoteStep.quoteDescription', 'Este es un precio anual estimado basado en el tamaño de tu empresa.')}
                  </Typography>
                  <Button
                    variant="outlined"
                    startIcon={<GetAppIcon />}
                    sx={{ mt: 3 }}
                    onClick={handleDownloadQuote}
                  >
                    {formType === 'partner' 
                     ? (i18n.language === 'en' 
                        ? t('pages.partners.requestForm.quoteStep.downloadButton') 
                        : 'Descargar Presupuesto') 
                     : t('pages.business.requestForm.quoteStep.downloadButton', 'Descargar Presupuesto')}
                  </Button>
                </Paper>
              </Box>
            )}
          </Box>
        );
      case 3:
        return (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {formType === 'partner' 
               ? (i18n.language === 'en' 
                  ? t('pages.partners.requestForm.additionalInfoStep.title') 
                  : 'Información Adicional') 
               : t('pages.business.requestForm.additionalInfoStep.title', 'Información Adicional')}
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              {formType === 'partner' 
               ? (i18n.language === 'en' 
                  ? t('pages.partners.requestForm.additionalInfoStep.subtitle') 
                  : '¿Tienes alguna pregunta o requisito específico?') 
               : t('pages.business.requestForm.additionalInfoStep.subtitle', '¿Tienes alguna pregunta o requisito específico?')}
            </Typography>
            <TextField
              margin="dense"
              id="additionalInfo"
              name="additionalInfo"
              label={formType === 'partner' 
                    ? (i18n.language === 'en' 
                       ? t('pages.partners.requestForm.additionalInfoStep.messageLabel') 
                       : 'Tu mensaje (opcional)') 
                    : t('pages.business.requestForm.additionalInfoStep.messageLabel', 'Tu mensaje (opcional)')}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={formData.additionalInfo}
              onChange={handleChange}
              sx={{ mt: 2 }}
            />
          </Box>
        );
      case 4:
        return (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 80, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              {formType === 'partner' 
               ? (i18n.language === 'en' 
                  ? t('pages.partners.requestForm.confirmationStep.title') 
                  : '¡Gracias!') 
               : t('pages.business.requestForm.confirmationStep.title', '¡Gracias!')}
            </Typography>
            <Typography variant="body1" paragraph>
              {formType === 'partner' 
               ? (i18n.language === 'en' 
                  ? t('pages.partners.requestForm.confirmationStep.infoMessage') 
                  : 'Hemos recibido tu solicitud de información. Nuestro equipo se pondrá en contacto contigo en breve con más detalles.') 
               : t('pages.business.requestForm.confirmationStep.infoMessage', 'Hemos recibido tu solicitud de información. Nuestro equipo se pondrá en contacto contigo en breve con más detalles.')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formType === 'partner' 
               ? (i18n.language === 'en' 
                  ? t('pages.partners.requestForm.confirmationStep.advisorMessage') 
                  : 'Alguien de partners@tuttogym.com se pondrá en contacto contigo pronto.') 
               : t('pages.business.requestForm.confirmationStep.advisorMessage', 'Alguien de ventas@tuttogym.com se pondrá en contacto contigo pronto.')}
            </Typography>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  const handleDialogClose = () => {
    // Clear any pending timeouts
    clearTimeout(window.saveProgressTimeout);
    
    // Save progress when dialog is closed
    saveFormProgress().then(() => {
      onClose();
      // Reset form after closing
      setActiveStep(0);
    });
  };

  // Save data when component unmounts
  useEffect(() => {
    return () => {
      // Clear any pending timeouts
      clearTimeout(window.saveProgressTimeout);
      
      // Save any unsaved data
      if (sessionId && Object.values(formData).some(value => value)) {
        saveFormProgress();
      }
    };
  }, [sessionId, formData]);

  return (
    <ModalPortal open={open}>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden',
          },
        }}
        container={document.getElementById('modal-root')}
        disableEnforceFocus
        hideBackdrop={false}
      >
        <DialogTitle sx={{ 
          bgcolor: 'primary.main', 
          color: 'white',
          position: 'relative',
          py: 2
        }}>
          {formType === 'partner' 
            ? (i18n.language === 'en' 
               ? t('pages.partners.requestForm.title') 
               : 'Convertirse en Partner') 
            : formType === 'advisor' 
              ? t('pages.business.requestForm.advisorTitle', 'Contactar un Asesor')
              : t('pages.business.requestForm.title', 'Solicitar Información')}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 3 }}>
            {getStepContent(activeStep)}
          </Box>
        </DialogContent>
        <DialogActions 
          sx={{ 
            justifyContent: activeStep === 4 ? 'center' : 'space-between',
            py: 3,
            px: 3,
            borderTop: '1px solid',
            borderColor: 'divider'
          }}
        >
          {activeStep === 4 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleDialogClose}
            >
              {t('pages.business.requestForm.close', 'Close')}
            </Button>
          ) : (
            <>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                {t('pages.business.requestForm.back', 'Back')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={activeStep === 3 ? handleSubmit : handleNext}
              >
                {activeStep === 3 
                  ? t('pages.business.requestForm.submit', 'Submit') 
                  : t('pages.business.requestForm.next', 'Next')}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </ModalPortal>
  );
};

export default RequestInfoForm; 