import React, { useState, useEffect } from 'react';
import AppNavigation from '../components/AppNavigation';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  TextField,
  Alert,
  Snackbar,
  CircularProgress,
  Divider,
  Link,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Rating,
  Chip,
} from '@mui/material';
import tuttoGymLogo from '../images/tuttogym.jpeg';

// Determine API URL based on environment
const getApiUrl = () => {
  // Check if running in production (Vercel)
  if (typeof window !== 'undefined' && 
      window.location.hostname !== 'localhost' && 
      !window.location.hostname.includes('127.0.0.1')) {
    return 'https://api.tuttogym.com';
  }
  // For local development
  return 'http://localhost:3000';
};

const API_BASE_URL = getApiUrl();

const CheckInSimulator = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [checkInData, setCheckInData] = useState(null);
  const [navigationValue, setNavigationValue] = useState('checkin');
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    // Load locations from the locations.json file
    fetch('/src/data/locations.json')
      .then(response => response.json())
      .then(data => {
        setLocations(data.locations);
      })
      .catch(error => {
        console.error('Error loading locations:', error);
        // Fallback to hardcoded locations if the fetch fails
        setLocations([
          {
            id: "1",
            name: "VivaGym",
            address: "Calle Principal 123, Madrid",
            email: "vivagym@gmail.com",
            image: "https://placehold.co/600x400/orange/white?text=VivaGym",
            features: ["Cardio", "Pesas", "Clases", "Piscina"],
            rating: 4.8,
            reviews: 245
          },
          {
            id: "2",
            name: "CanFelipa",
            address: "Avenida Diagonal 456, Barcelona",
            email: "canfeli@gmail.com",
            image: "https://placehold.co/600x400/orange/white?text=CanFelipa",
            features: ["Cardio", "Pesas", "Clases", "Yoga"],
            rating: 4.6,
            reviews: 189
          },
          {
            id: "3",
            name: "OkeyMass",
            address: "Plaza del Sur 789, Madrid",
            email: "okeymass@gmail.com",
            image: "https://placehold.co/600x400/orange/white?text=OkeyMass",
            features: ["Cardio", "Pesas", "CrossFit", "Boxeo"],
            rating: 4.7,
            reviews: 203
          }
        ]);
      });
  }, []);

  // Función para generar un ID único
  const generateId = () => {
    return 'check-' + Math.random().toString(36).substring(2, 10);
  };

  // Función para generar un hash único
  const generateHash = () => {
    return Array(20)
      .fill(0)
      .map(() => Math.random().toString(36).charAt(2))
      .join('');
  };

  const handleCheckIn = async (gymLocation) => {
    if (!userName.trim()) {
      setError('Por favor, introduce tu nombre');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Crear datos del check-in
      const newCheckInData = {
        id: generateId(),
        userId: 'user-' + Math.random().toString(36).substring(2, 10),
        userName: userName,
        timestamp: new Date().toISOString(),
        hash: generateHash(),
        status: 'pending',
        location: gymLocation.name,
        comments: '',
        userEmail: gymLocation.email // Email of the gym for the panel
      };

      console.log('Check-in creado:', newCheckInData);
      
      // Send the check-in to the server API
      const response = await fetch(`${API_BASE_URL}/api/check-ins`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCheckInData),
      });
      
      if (!response.ok) {
        throw new Error('Error al enviar el check-in al servidor');
      }
      
      const responseData = await response.json();
      console.log('Respuesta del servidor:', responseData);
      
      // Store the check-in data for display
      setCheckInData(newCheckInData);
      
      // Start polling for check-in status
      startPollingCheckInStatus(newCheckInData.id);
      
      setLoading(false);
      setSuccess(true);
      
      // Limpiar el formulario
      setUserName('');
    } catch (err) {
      console.error('Error al realizar check-in:', err);
      setLoading(false);
      setError('Error al procesar el check-in. Inténtalo de nuevo.');
    }
  };
  
  // Function to poll for check-in status
  const startPollingCheckInStatus = (checkInId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/check-ins/${checkInId}/status`);
        
        if (!response.ok) {
          throw new Error('Error al obtener el estado del check-in');
        }
        
        const statusData = await response.json();
        console.log('Estado del check-in:', statusData);
        
        // Update the check-in data with the new status
        setCheckInData(prevData => {
          if (prevData && prevData.id === checkInId) {
            return { ...prevData, status: statusData.status, comments: statusData.comments };
          }
          return prevData;
        });
        
        // If the check-in is no longer pending, stop polling
        if (statusData.status !== 'pending') {
          clearInterval(intervalId);
        }
      } catch (err) {
        console.error('Error al obtener el estado del check-in:', err);
        clearInterval(intervalId);
      }
    }, 2000); // Poll every 2 seconds
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
  };

  const copyScriptToClipboard = () => {
    const script = `
// Función para enviar un check-in de prueba
function sendTestCheckIn() {
  // Datos del check-in
  const checkInData = ${JSON.stringify(checkInData, null, 2)};
  
  // Buscar el componente Dashboard
  const dashboardComponent = document.querySelector('[data-testid="dashboard"]');
  
  if (!dashboardComponent) {
    console.error('No se pudo encontrar el componente Dashboard');
    return;
  }
  
  // Acceder a las propiedades de React
  const reactInstance = dashboardComponent[Object.keys(dashboardComponent).find(key => key.startsWith('__reactFiber$'))];
  
  if (!reactInstance) {
    console.error('No se pudo acceder a la instancia de React');
    return;
  }
  
  // Navegar hasta el componente Dashboard
  let component = reactInstance;
  while (component && (!component.stateNode || !component.stateNode.setCheckins)) {
    component = component.return;
  }
  
  if (!component || !component.stateNode) {
    console.error('No se pudo encontrar el estado del componente Dashboard');
    return;
  }
  
  // Actualizar el estado
  component.stateNode.setCheckins(prev => [checkInData, ...prev]);
  component.stateNode.setNotifications(prev => [checkInData, ...prev]);
  
  console.log('✅ Check-in añadido al panel correctamente');
}

// Ejecutar la función
sendTestCheckIn();
    `;
    
    navigator.clipboard.writeText(script)
      .then(() => {
        alert('Script copiado al portapapeles. Ahora ve al panel y pégalo en la consola.');
      })
      .catch(err => {
        console.error('Error al copiar el script:', err);
        alert('No se pudo copiar el script. Por favor, cópialo manualmente.');
      });
  };

  return (
    <Box sx={{ py: 4, bgcolor: 'background.default', minHeight: '100vh' }}>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
            <Box
              component="img"
              src={tuttoGymLogo}
              alt="TuttoGym Logo"
              sx={{ height: 60, mb: 2 }}
            />
            <Typography variant="h5" component="h1" gutterBottom>
              Simulador de Check-In
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              Esta página simula la aplicación móvil para realizar check-ins en gimnasios.
              Los check-ins realizados aquí aparecerán automáticamente en el panel de administración.
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          <Box component="form" noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label="Tu nombre"
              name="userName"
              autoComplete="name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              disabled={loading}
              sx={{ mb: 3 }}
            />
          </Box>
          
          <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            Gimnasios cercanos
          </Typography>
          
          <Grid container spacing={3}>
            {locations.map((location) => (
              <Grid item xs={12} key={location.id}>
                <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, height: '100%' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: { xs: '100%', sm: 150 }, height: { xs: 140, sm: '100%' } }}
                    image={location.image}
                    alt={location.name}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography component="div" variant="h6">
                        {location.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {location.address}
                      </Typography>
                      <Box sx={{ mt: 1, p: 1, bgcolor: 'background.paper', borderRadius: 1, border: '1px dashed', borderColor: 'primary.main' }}>
                        <Typography variant="body2" color="primary" sx={{ fontWeight: 'medium' }}>
                          <strong>Panel de Administración:</strong>
                        </Typography>
                        <Typography variant="body2">
                          Email: <strong>{location.email}</strong>
                        </Typography>
                        <Typography variant="body2">
                          Contraseña: <strong>admin123</strong>
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <Rating value={location.rating} precision={0.1} size="small" readOnly />
                        <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                          ({location.reviews} reseñas)
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {location.features.map((feature) => (
                          <Chip key={feature} label={feature} size="small" variant="outlined" />
                        ))}
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="info"
                        size="small"
                        onClick={() => handleCheckIn(location)}
                        disabled={loading}
                        sx={{ ml: 'auto' }}
                      >
                        {loading ? (
                          <>
                            <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                            Procesando...
                          </>
                        ) : (
                          'Check-In'
                        )}
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          {checkInData && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                Estado del Check-In
              </Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="body1" sx={{ mr: 2 }}>
                  Estado:
                </Typography>
                <Chip
                  label={checkInData.status === 'pending' ? 'Pendiente' : 
                         checkInData.status === 'approved' ? 'Aprobado' : 
                         checkInData.status === 'rejected' ? 'Rechazado' : 'Cancelado'}
                  color={checkInData.status === 'pending' ? 'warning' : 
                         checkInData.status === 'approved' ? 'success' : 'error'}
                />
              </Box>
              
              {checkInData.status === 'pending' && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  Tu check-in está pendiente de aprobación. Se cancelará automáticamente después de 2 minutos si no es aprobado.
                </Alert>
              )}
              
              {checkInData.status === 'approved' && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  ¡Tu check-in ha sido aprobado!
                </Alert>
              )}
              
              {checkInData.status === 'rejected' && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Tu check-in ha sido rechazado.
                  {checkInData.comments && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Motivo: {checkInData.comments}
                    </Typography>
                  )}
                </Alert>
              )}
              
              <Typography variant="body2" sx={{ mt: 2 }}>
                Puedes ver este check-in en el <Link href="/panel/dashboard" target="_blank" rel="noopener">panel de administración</Link>.
              </Typography>
              
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Detalles del check-in:
              </Typography>
              <Box component="pre" sx={{ 
                bgcolor: 'grey.100', 
                p: 2, 
                borderRadius: 1, 
                fontSize: '0.8rem',
                overflow: 'auto',
                maxHeight: '150px'
              }}>
                {JSON.stringify(checkInData, null, 2)}
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('/panel')}
              sx={{ mr: 2 }}
            >
              Ir al Panel
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => navigate('/')}
            >
              Volver al Inicio
            </Button>
          </Box>
        </Paper>
        
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2, mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Instrucciones
          </Typography>
          
          <Typography variant="body2" paragraph>
            1. Introduce tu nombre en el campo de texto
          </Typography>
          
          <Typography variant="body2" paragraph>
            2. Haz clic en "Realizar Check-In" para simular un check-in
          </Typography>
          
          <Typography variant="body2" paragraph>
            3. Sigue las instrucciones para ver el check-in en el panel
          </Typography>
          
          <Typography variant="body2" paragraph>
            <strong>Nota:</strong> El panel comienza vacío. Debes enviar check-ins desde este simulador para verlos en el panel.
          </Typography>
          
          <Typography variant="body2" paragraph>
            <strong>Credenciales del panel:</strong> Email: admin@tuttogym.com / Contraseña: admin123
          </Typography>
          
          <Typography variant="body2" paragraph>
            <strong>Método alternativo:</strong> También puedes usar el script <code>src/test/simpleCheckIn.js</code> para enviar check-ins directamente desde la consola del panel.
          </Typography>
        </Paper>
      </Container>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          ¡Check-in realizado con éxito! Sigue las instrucciones para verlo en el panel.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CheckInSimulator; 